import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from 'formik';
import { yupErrorMapping } from '../../helpers';
import TitleHeader from '../../components/titleHeader';
import Input from '../../components/input';
import Button from '../../components/button';
import { useAxios } from '../../hooks/useAxios';
import { loginSchema } from './FormLoginSchema';
import i18n from '../../i18n';
import Alert from '../../components/alert';
import { updateAppStatus, updateAuthUser } from '../../sagas/global/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import Constants from '../../constants';
import { useLocation } from 'react-router-dom';
import { login } from '../../api/authentication';
import { useRedirect } from '../../hooks/useRedirect';

const LoginForm = () => {
  const browserLocation = useLocation();
  const redirectReload = useRedirect(true);
  const dispatch = useDispatch();
  const appStatus = useSelector(
    (state: AppState) => state.globalReducer.appStatus
  );
  const changeLanguage = (lng: 'en' | 'ar') => {
    const htmlDoc = document.getElementsByTagName('html')[0];
    htmlDoc.setAttribute('dir', lng == 'ar' ? 'rtl' : 'ltr');
    htmlDoc.setAttribute('lang', lng);
    dispatch(
      updateAppStatus({ appStatus: { ...appStatus, currentLang: lng } })
    );
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    let lang = Constants.defaultLang as 'en' | 'ar';
    if (browserLocation.pathname.includes('/en/')) lang = 'en';
    else if (browserLocation.pathname.includes('/ar/')) lang = 'ar';
    changeLanguage(lang);
  }, [browserLocation.pathname]);
  const { axios } = useAxios();
  const [errors, setErrors] = useState<string[]>([]);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate: async (values) => {
      return await yupErrorMapping(loginSchema(), values);
    },
    onSubmit: async (values) => {
      const loginApi = login(axios);
      const response: any = await loginApi(values);
      if (response.error) {
        let errors: Array<string> = [];
        if (typeof response.message === 'string') {
          errors.push(response.message);
        } else {
          Object.keys(response.message).forEach((key: any) => {
            errors = [...errors, ...response.message[key]];
          });
        }
        setErrors(errors);
      } else {
        dispatch(updateAuthUser({ authUser: response }));
        redirectReload('/');
      }
    },
  });

  return (
    <Container className='pt-0'>
      <Row className={'me-0 ms-0'}>
        <Col md={1}></Col>
        <Col>
          <form onSubmit={(event) => event.preventDefault()} autoComplete='on'>
            <Row className={'me-0 ms-0'}>
              <Col md={12}>
                <TitleHeader
                  type='title'
                  text={i18n.t('pages.login.title')}
                  hasUnderline
                  align='center'
                />
              </Col>
              {errors.length > 0 && (
                <Col xs={12} className={'mt-3'}>
                  <Alert
                    type='error'
                    multiple
                    content={
                      <div className='d-flex flex-column'>
                        {errors.map((error, i) => (
                          <p key={i} className='mb-0'>
                            {error}
                          </p>
                        ))}
                      </div>
                    }
                  />
                </Col>
              )}
              <Col md={12} className='mt-4'>
                <Input
                  type='email'
                  name='email'
                  labelText={`${i18n.t('pages.login.email-input-label')}`}
                  placeholder={`${i18n.t(
                    'pages.login.email-input-placeholder'
                  )}`}
                  textValue={formik.values.email}
                  onBlur={(e: any) => {
                    formik.setFieldTouched('email', true);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('email', e.currentTarget.value);
                  }}
                  validationCheck={
                    formik.touched.email
                      ? formik.errors.email
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : undefined
                  }
                />
              </Col>
              <Col md={12} className='mt-4'>
                <Input
                  dir='ltr'
                  type='password'
                  labelText={`${i18n.t('pages.login.password-input-label')}`}
                  placeholder={`${i18n.t(
                    'pages.login.password-input-placeholder'
                  )}`}
                  textValue={formik.values.password}
                  onBlur={(e: any) => {
                    formik.setFieldTouched('password', true);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('password', e.currentTarget.value);
                  }}
                  validationCheck={
                    formik.touched.password
                      ? formik.errors.password
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.password && formik.errors.password
                      ? formik.errors.password
                      : undefined
                  }
                />
              </Col>
              <Col md={12} className='mt-5 d-flex justify-content-center'>
                <Button
                  type='submit'
                  styleType='light'
                  text={i18n.t('pages.login.login-button')}
                  className='w-100'
                  disabled={
                    Object.keys(formik.errors).length > 0 ||
                    Object.keys(formik.touched).length === 0
                  }
                  onClick={async () => {
                    formik.submitForm();
                  }}
                />
              </Col>
            </Row>
          </form>
        </Col>
        <Col md={1}></Col>
      </Row>
    </Container>
  );
};

LoginForm.displayName = 'LoginForm';
export default LoginForm;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
