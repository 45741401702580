import { AxiosError } from 'axios';
import { IsJsonString } from './index';
import { IOrganization } from '../dtos/IOrganization';

/* eslint-disable camelcase */
export const getApiErrorMessage = (
  error: AxiosError
): { error: string; message: string } => {
  return {
    error: error.response
      ? (error.response.data as any)?.error || error.message
      : error.message,
    message: error.response
      ? (error.response.data as any)?.message || error.message
      : error.message,
  };
};

export const getImageProp = (
  obj: any,
  imgPropName: string,
  defaultVal?: string
) => {
  if (obj && obj[imgPropName]) {
    return obj[imgPropName].trim() !== '' ? obj[imgPropName] : defaultVal;
  }
  return defaultVal;
};

export const mapLink = (url: string) => {
  if (url.includes('http')) return url;
  return 'http://' + url;
};

export const mapStringToJson = (arr: string[]) => {
  return arr
    .map((val) => {
      if (IsJsonString(val)) {
        return JSON.parse(val);
      }
      return null;
    })
    .filter((val) => val !== null);
};

export const getOrgProp = (
  organization: any | null,
  prop: string,
  defaultVal?: any
) => {
  let propVal = defaultVal ?? '';
  const typeObj =
    organization.trainer ||
    organization.marketer ||
    organization.coordinator ||
    organization.company;
  if (organization && (organization.user as any)[prop]) {
    propVal = (organization.user as any)[prop];
  } else if (organization && (organization as any)[prop]) {
    propVal = (organization as any)[prop];
  } else if (organization && typeObj && (typeObj as any)[prop]) {
    propVal = (typeObj as any)[prop];
  } else if (typeObj && typeObj.social && (typeObj.social as any)[prop]) {
    propVal = (typeObj.social as any)[prop];
  }
  return propVal;
};
