import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Card } from 'antd';
import TitleHeader from '../../components/titleHeader';
import i18n from '../../i18n';
import { useParams } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios';
import { fetchConsultationRequest } from '../../api/consultationRequests';
import { useGetLocalProp } from '../../hooks/getLocalProp';

interface IViewConsultationRequestContainerProps {
  data?: any;
}

function ViewConsultationRequestContainer(
  props: IViewConsultationRequestContainerProps
) {
  const dispatch = useDispatch();
  const { getLocalProp } = useGetLocalProp();
  const { id } = useParams();
  const { axios } = useAxios();
  const [consultationRequest, setConsultationRequest] = useState<any>();
  useEffect(() => {
    async function loadConsultationRequest() {
      const results: any = await fetchConsultationRequest(axios)(id as string);
      if (!('error' in results)) {
        setConsultationRequest(results);
      }
    }
    if (id) loadConsultationRequest();
  }, []);

  return (
    <Row className={'m-0 p-0'}>
      <Col sm={12} className={'p-0 text-center'}>
        <Card>
          <Row className={'m-0 p-0'}>
            <Col sm={12} className={'p-0 text-center'}>
              <TitleHeader
                type='subtitle'
                text={`${i18n.t('global.email')}: ${
                  consultationRequest?.email
                }`}
              />
            </Col>
            <Col sm={12} className={'p-0 text-center'}>
              {consultationRequest?.serviceType && (
                <TitleHeader
                  type='subtitle'
                  text={`${i18n.t('global.serviceType')}: ${getLocalProp(
                    consultationRequest?.serviceType,
                    'name',
                    ''
                  )}`}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Col>
      <Col sm={12} className={'mt-4 p-0 text-center'}>
        <Card>
          <Row className={'m-0 p-0'}>
            <Col
              sm={12}
              className={'p-0 text-center d-flex justify-content-center'}
            >
              <TitleHeader type='title' text={`${i18n.t('global.content')}`} />
            </Col>
            <Col sm={12} className={'p-0 text-center'}>
              {consultationRequest?.content}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default ViewConsultationRequestContainer;
