import React, { useEffect, useState } from 'react';
import { IHeaderColumn, IRow } from '../../components/dataTable';
import { handleSortTableColumn } from '../../helpers/logic';
import { Dropdown, Switch } from 'antd';
import { AiOutlineMore } from 'react-icons/ai';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import i18n from '../../i18n';
import Constants from '../../constants';
import Permission from '../../enum/permission.enum';
import { toast } from 'react-toastify';
import { updateOrganizationRequest } from '../../api/organizationUpdateRequests';

export const headerColumnsBuilder = (
  searchParamsOrderby: { columnName: string; sort: 'ASC' | 'DESC' }[],
  searchParams: any,
  setSearchParams: (val: any) => void
): IHeaderColumn[] => {
  let columns: IHeaderColumn[] = [
    {
      name: 'id',
      displayedName: 'Id',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'id'));
      },
    },
    {
      name: 'name',
      displayedName: 'Organization name',
    },
    {
      name: 'commercial_registration_number',
      displayedName: 'Commercial registration number',
    },
    {
      name: 'license_number',
      displayedName: 'License number',
    },
    {
      name: 'approved',
      displayedName: 'Approved',
    },
    {
      name: 'created_at',
      displayedName: 'Created at',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'created_at'));
      },
    },
    {
      name: 'actions',
      displayedName: 'Actions',
    },
  ];

  searchParamsOrderby.forEach((val, i) => {
    columns = columns.map((column) => {
      return {
        ...column,
        sorted: column.name === val.columnName ? val.sort : column.sorted,
      };
    });
  });
  return columns;
};

export const rowsBuilder = (
  updateRequests: any[],
  loadedUpdateRequests: any[],
  setLoadedUpdateRequests: any,
  getLocalProp: any,
  setShowDeleteAlert: (val: { show: boolean; id: string }) => void,
  canAccess: any,
  axiosInstance: any
): IRow[] => {
  return updateRequests.map((val, i) => ({
    dataObj: val,
    props: [
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.id}</span>;
        },
        onCellClick(e: any, rowValue: any) {
          location.href =
            i18n.language === Constants.defaultLang
              ? `/organization_update_requests/${rowValue.id}`
              : `/${i18n.language}/organization_update_requests/${rowValue.id}`;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.name}</span>;
        },
        onCellClick(e: any, rowValue: any) {
          location.href =
            i18n.language === Constants.defaultLang
              ? `/organization_update_requests/${rowValue.id}`
              : `/${i18n.language}/organization_update_requests/${rowValue.id}`;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.commercial_registration_number}</span>;
        },
        onCellClick(e: any, rowValue: any) {
          location.href =
            i18n.language === Constants.defaultLang
              ? `/organization_update_requests/${rowValue.id}`
              : `/${i18n.language}/organization_update_requests/${rowValue.id}`;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.license_number}</span>;
        },
        onCellClick(e: any, rowValue: any) {
          location.href =
            i18n.language === Constants.defaultLang
              ? `/organization_update_requests/${rowValue.id}`
              : `/${i18n.language}/organization_update_requests/${rowValue.id}`;
        },
      },
      {
        loadContent: (rowValue) => {
          return (
            <span>
              <Switch
                checked={rowValue.approved}
                onChange={(checked) => {
                  setLoadedUpdateRequests(
                    loadedUpdateRequests.map((updateRequest, i) => {
                      return updateRequest.id == rowValue.id
                        ? { ...updateRequest, approved: checked }
                        : updateRequest;
                    })
                  );
                  handleEdit(
                    axiosInstance,
                    {
                      approved: checked,
                    },
                    rowValue.id
                  );
                }}
              />
            </span>
          );
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.created_at}</span>;
        },
        onCellClick(e: any, rowValue: any) {
          location.href =
            i18n.language === Constants.defaultLang
              ? `/organization_update_requests/${rowValue.id}`
              : `/${i18n.language}/organization_update_requests/${rowValue.id}`;
        },
      },
      {
        loadContent: (rowValue) => {
          const actionItems: any[] = [];
          if (
            canAccess({
              permissions: [Permission.DELETE_UPDATE_REQUEST],
            })
          ) {
            actionItems.push({
              label: 'Delete',
              key: '2',
              icon: <DeleteOutlined />,
              onClick: () => {
                setShowDeleteAlert({ show: true, id: rowValue.id });
              },
            });
          }
          return (
            <span>
              <Dropdown
                menu={{
                  items: actionItems,
                }}
              >
                <AiOutlineMore size={30} />
              </Dropdown>
            </span>
          );
        },
      },
    ],
  }));
};

const handleEdit = async (axiosInstance: any, values: any, id: string) => {
  const results: any = await updateOrganizationRequest(axiosInstance)(
    values,
    id
  );
  if (results.error) {
    const toastMsg = i18n.t('global.entityUpdateFailed', {
      entity: i18n.t('global.entities.updateRequest'),
    });
    toast.error(toastMsg, {
      position: i18n.language == 'ar' ? 'top-left' : 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  } else {
    const toastMsg = i18n.t('global.entityUpdatedSuccessfully', {
      entity: i18n.t('global.entities.updateRequest'),
    });
    toast.success(toastMsg, {
      position: i18n.language == 'ar' ? 'top-left' : 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }
};
