import Constants from '../constants';
import { AxiosInstance } from 'axios';
import { getApiErrorMessage } from '../helpers/mappers';

let loadBagsController: any;

export const fetchBags =
  (axiosInstance: AxiosInstance) =>
  (
    page?: string,
    search?: string,
    orderby?: { [key: string]: 'ASC' | 'DESC' }[]
  ) => {
    try {
      if (loadBagsController) loadBagsController.abort();
      loadBagsController = new AbortController();
      const response = axiosInstance.get('/dashboard_bags', {
        params: { page, search, orderby },
        signal: loadBagsController.signal,
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const fetchBag =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.get(`/dashboard_bags/${id}`);
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const createBag = (axiosInstance: AxiosInstance) => async (bag: any) => {
  try {
    const response = await axiosInstance.post(
      '/dashboard_bags',
      {
        title_en: bag.title_en,
        title_ar: bag.title_ar,
        badge_en: bag.badge_en,
        badge_ar: bag.badge_ar,
        brief_en: bag.brief_en,
        brief_ar: bag.brief_ar,
        price: bag.price,
        keywords: bag.keywords,
        contents: bag.contents,
        categories: bag.categories,
        subCategories: bag.subCategories,
        profile_image: bag.photo,
        content_image: bag.contentPhoto,
        file: bag.bagFile,
      },
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
    return response;
  } catch (err: any) {
    return getApiErrorMessage(err);
  }
};

export const updateBag =
  (axiosInstance: AxiosInstance) => async (bag: any, id: string) => {
    try {
      let bagData: any = {
        title_en: bag.title_en,
        title_ar: bag.title_ar,
        badge_en: bag.badge_en,
        badge_ar: bag.badge_ar,
        brief_en: bag.brief_en,
        brief_ar: bag.brief_ar,
        price: bag.price,
        keywords: bag.keywords,
        contents: bag.contents,
        categories: bag.categories,
        subCategories: bag.subCategories,
      };
      if (bag.photo && typeof bag.photo !== 'string') {
        bagData = {
          ...bagData,
          profile_image: bag.photo,
        };
      }
      if (bag.contentPhoto && typeof bag.contentPhoto !== 'string') {
        bagData = {
          ...bagData,
          content_image: bag.contentPhoto,
        };
      }
      if (bag.bagFile && typeof bag.bagFile !== 'string') {
        bagData = {
          ...bagData,
          file: bag.bagFile,
        };
      }

      const response = await axiosInstance.post(
        `/dashboard_bags/${id}`,
        bagData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updatePartialBag =
  (axiosInstance: AxiosInstance) => async (bag: any, id: string) => {
    try {
      let bagData: any = {
        title_en: bag.title_en,
        title_ar: bag.title_ar,
      };
      if (bag.photo && typeof bag.photo !== 'string') {
        bagData = {
          ...bagData,
          profile_image: bag.photo,
        };
      }
      const response = await axiosInstance.post(
        `/dashboard_bags/${id}/partial`,
        bagData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const deleteBag =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.delete(`/dashboard_bags/${id}`);
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };
