import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { validateAccess } from '../../helpers/logic';

export type CanProps = {
  children: any;
  permissions: string[];
  roles?: string[];
  permissionsOperator?: 'AND' | 'OR';
  rolesOperator?: 'AND' | 'OR';
};

const Can = React.forwardRef(
  (
    {
      children,
      permissions,
      roles,
      permissionsOperator = 'AND',
      rolesOperator = 'AND',
    }: CanProps,
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const authUser = useSelector(
      (state: AppState) => state.globalReducer.authUser
    );
    const [hasAccess, setHasAccess] = useState<boolean>(false);
    useEffect(() => {
      if (authUser) {
        const userPermissions = authUser.user.permissions ?? [];
        const userRoles = authUser.user.roles ?? [];
        let hasRoleAccess = true;
        let hasPermissionAccess = false;
        if (roles) {
          hasRoleAccess = validateAccess(userRoles, roles, rolesOperator);
        }
        if (permissions.length)
          hasPermissionAccess = validateAccess(
            userPermissions,
            permissions,
            permissionsOperator
          );
        setHasAccess(hasRoleAccess && hasPermissionAccess);
      }
    }, [roles, permissions]);
    return <>{hasAccess && children}</>;
  }
);

Can.displayName = 'Can';
export default Can;
