/* eslint-disable camelcase */
import { AxiosInstance } from 'axios';
import { getApiErrorMessage } from '../helpers/mappers';

export const login =
  (axiosInstance: AxiosInstance) => async (formData: any) => {
    try {
      const response = await axiosInstance.post(
        '/authentication/dashboard_login',
        {
          email: formData.email,
          password: formData.password,
        }
      );
      typeof window !== 'undefined'
        ? window.localStorage.setItem('auth', JSON.stringify(response))
        : false;
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const logout = (axiosInstance: AxiosInstance) => async () => {
  try {
    const response = await axiosInstance.get('/authentication/logout');
    return response;
  } catch (err: any) {
    return getApiErrorMessage(err);
  }
};

export const updatePassword =
  (axiosInstance: AxiosInstance) => async (formData: any) => {
    try {
      const response = await axiosInstance.post(
        '/authentication/update_password',
        {
          currentPassword: formData.currentPassword,
          newPassword: formData.password,
        }
      );
      typeof window !== 'undefined'
        ? window.localStorage.setItem('auth', JSON.stringify(response))
        : false;
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };
