(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("lodash"), require("@fortawesome/react-fontawesome"), require("@fortawesome/free-solid-svg-icons"), require("react-content-loader"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "lodash", "@fortawesome/react-fontawesome", "@fortawesome/free-solid-svg-icons", "react-content-loader"], factory);
	else if(typeof exports === 'object')
		exports["lib"] = factory(require("react"), require("lodash"), require("@fortawesome/react-fontawesome"), require("@fortawesome/free-solid-svg-icons"), require("react-content-loader"));
	else
		root["lib"] = factory(root["react"], root["lodash"], root["@fortawesome/react-fontawesome"], root["@fortawesome/free-solid-svg-icons"], root["react-content-loader"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__TXh__, __WEBPACK_EXTERNAL_MODULE_tMnu__, __WEBPACK_EXTERNAL_MODULE_qgMS__, __WEBPACK_EXTERNAL_MODULE_F4WN__, __WEBPACK_EXTERNAL_MODULE_boN6__) => {
return 