import Constants from '../constants';
import { AxiosInstance } from 'axios';
import { getApiErrorMessage } from '../helpers/mappers';
import websitePages from '../enum/websitePages';

export const fetchPageSettings =
  (axiosInstance: AxiosInstance) => async (name: websitePages) => {
    try {
      const response = await axiosInstance.get(
        `/dashboard_pages_settings/${name}`
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updatePageSettings =
  (axiosInstance: AxiosInstance) => async (pageSettings: any, id: string) => {
    try {
      const pageSettingsData: any = {
        seo: JSON.stringify(pageSettings.seo),
        settings: JSON.stringify(pageSettings.settings),
      };
      const response = await axiosInstance.post(
        `/dashboard_pages_settings/${id}`,
        pageSettingsData
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };
