import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from 'formik';
import { randomStr, yupErrorMapping } from '../../helpers';
import TitleHeader from '../../components/titleHeader';
import Input from '../../components/input';
import Button from '../../components/button';
import { useAxios } from '../../hooks/useAxios';
import { organizationSchema } from './OrganizationFormSchema';
import i18n from '../../i18n';
import Alert from '../../components/alert';
import Constants from '../../constants';
import { Avatar } from 'antd';
import FileUpload from '../../components/file-upload';
import Select from '../../components/select';
import PortalModal from '../../components/portalModal';
import { renderError } from '../../helpers/renderers';
import { useGetLocalProp } from '../../hooks/getLocalProp';
import _ from 'lodash';
import { getServiceTypesList } from '../../api/serviceTypes';
import { getCourseTypesList } from '../../api/courseTypes';
import RichBox from '../../components/richBox';
import { ITrainer } from '../../dtos/ITrainer';
import { IUser } from '../../dtos/IUser';
import { ICoordinator } from '../../dtos/ICoordinator';
import { IMarketer } from '../../dtos/IMarketer';
import { ICompany } from '../../dtos/ICompany';
import { getOrgProp } from '../../helpers/mappers';
interface IOrganizationFormProps {
  organization?: {
    password: string;
    serviceType_id: number | string;
    name: string;
    license_number: string;
    commercial_registration_number: string;
    communication_officer: string;
    courseTypes: any[];
    official_website_url: string;
    user: IUser;
    trainer?: ITrainer;
    coordinator?: ICoordinator;
    marketer?: IMarketer;
    company?: ICompany;
    profile_image_url: string;
  };
  submitForm: (formValues: any) => void;
  errors: string[];
}

const OrganizationForm = ({
  organization,
  submitForm,
  errors,
}: IOrganizationFormProps) => {
  const currentLang = i18n.language;
  const { axios } = useAxios();
  const { getLocalProp } = useGetLocalProp();
  const [selectCountryCode, setSelectCountryCode] = useState<
    { label: string; value: string } | undefined
  >();
  const [selectImage, setSelectImage] = useState<string | undefined>();
  const profileImgRef = useRef<any>(null);
  const handleClick = () => {
    profileImgRef.current?.click();
  };
  const [isKeywordsModalOpen, setIsKeywordsModalOpen] = useState(false);
  const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);
  const [keywordsHasErrors, setKeywordsHasErrors] = useState(false);
  const [skillsHasErrors, setSkillsHasErrors] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [courseTypes, setCourseTypes] = useState([]);
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
      serviceType_id: { label: '', value: 0 },
      name: '',
      license_number: '',
      commercial_registration_number: '',
      communication_officer: '',
      courseTypes: [],
      subtitle_en: '',
      subtitle_ar: '',
      badge_en: '',
      badge_ar: '',
      brief_en: '',
      brief_ar: '',
      wage: '',
      facebook_url: '',
      twitter_url: '',
      linkedin_url: '',
      contact_email: '',
      official_website_url: '',
      keywords: [],
      skills: [],
      email: '',
      phone_code: '',
      phone_number: '',
      photo: '',
    },
    validate: async (values) => {
      return await yupErrorMapping(
        organizationSchema(organization ? true : false),
        values
      );
    },
    onSubmit: async (values) => {
      const mappedValues = {
        ...values,
        serviceType_id: values.serviceType_id.value,
        courseTypes: values.courseTypes
          .filter((obj: any) => obj.value != 'all')
          .map((obj: any) => obj.value),
        keywords: values.keywords.map((obj: any) => ({ keyword: obj.text })),
        skills: values.skills.map((obj: any) => ({ skill: obj.text })),
      };
      submitForm(mappedValues);
    },
  });

  useEffect(() => {
    let haskeywordError = false;
    let hasskillError = false;
    Object.keys(formik.errors).forEach((errKey, i) => {
      if (errKey.includes('keywords')) {
        haskeywordError = true;
      }
      if (errKey.includes('skills')) {
        hasskillError = true;
      }
    });
    setKeywordsHasErrors(haskeywordError);
    setSkillsHasErrors(hasskillError);
  }, [formik.errors]);

  useEffect(() => {
    if (organization) {
      // image load
      if (organization.profile_image_url.trim() != '') {
        setSelectImage(organization.profile_image_url);
        formik.setFieldValue('photo', organization.profile_image_url);
      }
      // load code
      const userCountryCode = _.find(
        Constants.countryCodes,
        (country) => country.value == organization.user.phone_code
      );
      if (userCountryCode)
        setSelectCountryCode({
          label: i18n.t(`global.countries.${userCountryCode.key}`),
          value: userCountryCode.value,
        });
      formik.setFieldValue('phone_code', organization.user.phone_code);
      formik.setFieldValue('phone_number', organization.user.phone_number);
      // other feilds
      formik.setFieldValue('name', organization.name);
      formik.setFieldValue('email', organization.user.email);
      formik.setFieldValue(
        'communication_officer',
        organization.communication_officer
      );
      formik.setFieldValue(
        'commercial_registration_number',
        organization.commercial_registration_number
      );
      formik.setFieldValue('license_number', organization.license_number);
      formik.setFieldValue(
        'courseTypes',
        courseTypes.filter((c: any) =>
          organization.courseTypes.find((orgC) => c.value == orgC.id)
        )
      );
      formik.setFieldValue('serviceType_id', {
        label: '',
        value: getOrgProp(organization, 'serviceType_id', ''),
      });
      formik.setFieldValue(
        'subtitle_en',
        getOrgProp(organization, 'subtitle_en', '')
      );
      formik.setFieldValue(
        'subtitle_ar',
        getOrgProp(organization, 'subtitle_ar', '')
      );
      formik.setFieldValue(
        'badge_en',
        getOrgProp(organization, 'badge_en', '')
      );
      formik.setFieldValue(
        'badge_ar',
        getOrgProp(organization, 'badge_ar', '')
      );
      formik.setFieldValue(
        'brief_en',
        getOrgProp(organization, 'brief_en', '')
      );
      formik.setFieldValue(
        'brief_ar',
        getOrgProp(organization, 'brief_ar', '')
      );
      formik.setFieldValue('wage', getOrgProp(organization, 'wage', ''));
      formik.setFieldValue(
        'facebook_url',
        getOrgProp(organization, 'facebook_url', '')
      );
      formik.setFieldValue(
        'twitter_url',
        getOrgProp(organization, 'twitter_url', '')
      );
      formik.setFieldValue(
        'linkedin_url',
        getOrgProp(organization, 'linkedin_url', '')
      );
      formik.setFieldValue(
        'contact_email',
        getOrgProp(organization, 'contact_email', '')
      );
      formik.setFieldValue(
        'official_website_url',
        getOrgProp(organization, 'official_website_url', '')
      );
      formik.setFieldValue(
        'keywords',
        getOrgProp(organization, 'keywords', []).map((keyword: any) => ({
          key: randomStr(),
          text: keyword.keyword,
        }))
      );
      formik.setFieldValue(
        'skills',
        getOrgProp(organization, 'skills', []).map((skill: any) => ({
          key: randomStr(),
          text: skill.skill,
        }))
      );
      // setTouched
      setTimeout(() => {
        formik.setFieldTouched('phone_code', true);
        formik.setFieldTouched('phone_number', true);
        formik.setFieldTouched('name', true);
        formik.setFieldTouched('email', true);
        formik.setFieldTouched('communication_officer', true);
        formik.setFieldTouched('commercial_registration_number', true);
        formik.setFieldTouched('license_number', true);
        formik.setFieldTouched('courseTypes', true);
        formik.setFieldTouched('subtitle_en', true);
        formik.setFieldTouched('subtitle_ar', true);
        formik.setFieldTouched('badge_en', true);
        formik.setFieldTouched('badge_ar', true);
        formik.setFieldTouched('brief_en', true);
        formik.setFieldTouched('brief_ar', true);
        formik.setFieldTouched('wage', true);
        formik.setFieldTouched('facebook_url', true);
        formik.setFieldTouched('twitter_url', true);
        formik.setFieldTouched('linkedin_url', true);
        formik.setFieldTouched('contact_email', true);
        formik.setFieldTouched('official_website_url', true);
        formik.setFieldTouched('keywords', true);
        formik.setFieldTouched('skills', true);
      }, 300);
    }
  }, [organization]);

  useEffect(() => {
    async function loadServiceTypes() {
      const results: any = await getServiceTypesList(axios)();
      if (!('error' in results)) {
        setServiceTypes(
          results.map((val: any) => ({
            label: getLocalProp(val, 'name', ''),
            value: val.id,
          }))
        );
      } else {
        setServiceTypes([]);
      }
    }
    async function loadCourseTypes() {
      const results: any = await getCourseTypesList(axios)();
      if (!('error' in results)) {
        setCourseTypes(
          results.map((val: any) => ({
            label: getLocalProp(val, 'name', ''),
            value: val.id,
          }))
        );
      } else {
        setCourseTypes([]);
      }
    }
    loadServiceTypes();
    loadCourseTypes();
  }, []);

  return (
    <Container fluid className='p-0 pt-0'>
      <Row className={'me-0 ms-0'}>
        <Col className='p-0'>
          <Row className={'me-0 ms-0'}>
            {errors.length > 0 && (
              <Col xs={12} className={'mt-3'}>
                <Alert
                  type='error'
                  multiple
                  content={
                    <div className='d-flex flex-column'>
                      {errors.map((error, i) => (
                        <p key={i} className='mb-0'>
                          {error}
                        </p>
                      ))}
                    </div>
                  }
                />
              </Col>
            )}
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-4 d-flex justify-content-center'>
              <Avatar
                src={selectImage ?? 'https://via.placeholder.com/600'}
                size={70}
                className='pointer'
                alt='user-avatar'
                onClick={() => handleClick()}
              />
              <FileUpload
                ref={profileImgRef}
                accept='.jpg,.jpeg,.png,.webp,.gif'
                load={(file, base64) => {
                  formik.setFieldValue('photo', file);
                  setSelectImage(base64);
                }}
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t('global.name')}`}
                placeholder={`${i18n.t('global.name')}`}
                textValue={formik.values.name}
                onBlur={(e: any) => {
                  formik.setFieldTouched('name', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('name', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.name
                    ? formik.errors.name
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t('global.email')}`}
                placeholder={`${i18n.t('global.email')}`}
                textValue={formik.values.email}
                onBlur={(e: any) => {
                  formik.setFieldTouched('email', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('email', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.email
                    ? formik.errors.email
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='phone'
                labelText={`${i18n.t('global.phone-number')}`}
                placeholder={`${i18n.t('global.phone-number')}`}
                textValue={formik.values.phone_number}
                onBlur={(e: any) => {
                  formik.setFieldTouched('phone_number', true);
                  formik.validateField('phone_number');
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('phone_number', e.currentTarget.value);
                }}
                codePlaceholder={`${i18n.t('global.code')}`}
                countryCodes={Constants.countryCodes.map((country) => ({
                  label: i18n.t(`global.countries.${country.key}`),
                  value: country.value,
                }))}
                defaultSelectedCountry={selectCountryCode}
                onCountryCodeChange={(newValue, action) => {
                  setSelectCountryCode({
                    label: newValue.label,
                    value: newValue.value,
                  });
                  formik.setFieldValue('phone_code', newValue.value);
                }}
                validationCheck={
                  formik.touched.phone_number
                    ? formik.errors.phone_number
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.phone_number && formik.errors.phone_number
                    ? formik.errors.phone_number
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t(
                  'pages.organization.communication_officer'
                )}`}
                placeholder={`${i18n.t(
                  'pages.organization.communication_officer'
                )}`}
                textValue={formik.values.communication_officer}
                onBlur={(e: any) => {
                  formik.setFieldTouched('communication_officer', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue(
                    'communication_officer',
                    e.currentTarget.value
                  );
                }}
                validationCheck={
                  formik.touched.communication_officer
                    ? formik.errors.communication_officer
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.communication_officer &&
                  formik.errors.communication_officer
                    ? formik.errors.communication_officer
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t('pages.organization.license_number')}`}
                placeholder={`${i18n.t('pages.organization.license_number')}`}
                textValue={formik.values.license_number}
                onBlur={(e: any) => {
                  formik.setFieldTouched('license_number', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('license_number', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.license_number
                    ? formik.errors.license_number
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.license_number && formik.errors.license_number
                    ? formik.errors.license_number
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t(
                  'pages.organization.commercial_registration_number'
                )}`}
                placeholder={`${i18n.t(
                  'pages.organization.commercial_registration_number'
                )}`}
                textValue={formik.values.commercial_registration_number}
                onBlur={(e: any) => {
                  formik.setFieldTouched(
                    'commercial_registration_number',
                    true
                  );
                }}
                onChange={(e: any) => {
                  formik.setFieldValue(
                    'commercial_registration_number',
                    e.currentTarget.value
                  );
                }}
                validationCheck={
                  formik.touched.commercial_registration_number
                    ? formik.errors.commercial_registration_number
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.commercial_registration_number &&
                  formik.errors.commercial_registration_number
                    ? formik.errors.commercial_registration_number
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            {!organization && (
              <Col md={6} className='mt-4 text-align-start'>
                <Select
                  labelText={`${i18n.t('pages.organization.serviceType')}`}
                  placeholder={`${i18n.t('pages.organization.serviceType')}`}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={serviceTypes}
                  defaultSelectedValues={formik.values.serviceType_id as any}
                  onBlur={(e) => {
                    formik.setFieldTouched('serviceType_id', true);
                  }}
                  onChange={(newValue, action) => {
                    formik.setFieldValue('serviceType_id', newValue);
                  }}
                  errorMessage={
                    formik.touched.serviceType_id &&
                    formik.errors.serviceType_id
                      ? (formik.errors.serviceType_id as any)
                      : undefined
                  }
                />
              </Col>
            )}
            <Col md={6} className='mt-4 text-align-start'>
              <Select
                labelText={`${i18n.t('pages.organization.courseType')}`}
                placeholder={`${i18n.t('pages.organization.courseType')}`}
                isMulti
                hasSelectAll
                selectAllText={
                  i18n.t('pages.organization.allSections') as string
                }
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={courseTypes}
                defaultSelectedValues={formik.values.courseTypes}
                onBlur={(e) => {
                  formik.setFieldTouched('courseTypes', true);
                }}
                onChange={(newValue, action) => {
                  formik.setFieldValue('courseTypes', newValue);
                }}
                errorMessage={
                  formik.touched.courseTypes &&
                  formik.errors.courseTypes &&
                  formik.errors.courseTypes
                }
              />
            </Col>
          </Row>
          {!organization && (
            <Row className={'me-0 ms-0'}>
              <Col md={6} className='mt-4 text-align-start'>
                <Input
                  type='password'
                  labelText={`${i18n.t('global.password')}`}
                  placeholder={`${i18n.t('global.password')}`}
                  textValue={formik.values.password}
                  onBlur={(e: any) => {
                    formik.setFieldTouched('password', true);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('password', e.currentTarget.value);
                  }}
                  validationCheck={
                    formik.touched.password
                      ? formik.errors.password
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.password && formik.errors.password
                      ? formik.errors.password
                      : undefined
                  }
                />
              </Col>
              <Col md={6} className='mt-4 text-align-start'>
                <Input
                  type='password'
                  labelText={`${i18n.t('global.confirmPassword')}`}
                  placeholder={`${i18n.t('global.confirmPassword')}`}
                  textValue={formik.values.passwordConfirmation}
                  onBlur={(e: any) => {
                    formik.setFieldTouched('passwordConfirmation', true);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue(
                      'passwordConfirmation',
                      e.currentTarget.value
                    );
                  }}
                  validationCheck={
                    formik.touched.passwordConfirmation
                      ? formik.errors.passwordConfirmation
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.passwordConfirmation &&
                    formik.errors.passwordConfirmation
                      ? formik.errors.passwordConfirmation
                      : undefined
                  }
                />
              </Col>
            </Row>
          )}
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-3 d-flex justify-content-center'>
              <TitleHeader
                type='title'
                text={i18n.t('pages.organization.additionalData')}
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col
              md={6}
              className={`mt-4 text-align-start ${
                currentLang === 'ar' ? 'order-2' : ''
              }`}
            >
              <Input
                className='text-dir-ltr'
                type='text'
                labelText={`${i18n.t('pages.organization.subtitle_en')}`}
                placeholder={`${i18n.t('pages.organization.subtitle_en')}`}
                textValue={formik.values.subtitle_en}
                onBlur={(e: any) => {
                  formik.setFieldTouched('subtitle_en', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('subtitle_en', e.target.value);
                }}
                validationCheck={
                  formik.touched.subtitle_en
                    ? formik.errors.subtitle_en
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.subtitle_en && formik.errors.subtitle_en
                    ? formik.errors.subtitle_en
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                className='text-dir-rtl'
                type='text'
                labelText={`${i18n.t('pages.organization.subtitle_ar')}`}
                placeholder={`${i18n.t('pages.organization.subtitle_ar')}`}
                textValue={formik.values.subtitle_ar}
                onBlur={(e: any) => {
                  formik.setFieldTouched('subtitle_ar', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('subtitle_ar', e.target.value);
                }}
                validationCheck={
                  formik.touched.subtitle_ar
                    ? formik.errors.subtitle_ar
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.subtitle_ar && formik.errors.subtitle_ar
                    ? formik.errors.subtitle_ar
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col
              md={6}
              className={`mt-4 text-align-start ${
                currentLang === 'ar' ? 'order-2' : ''
              }`}
            >
              <Input
                className='text-dir-ltr'
                type='text'
                labelText={`${i18n.t('pages.organization.badge_en')}`}
                placeholder={`${i18n.t('pages.organization.badge_en')}`}
                textValue={formik.values.badge_en}
                onBlur={(e: any) => {
                  formik.setFieldTouched('badge_en', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('badge_en', e.target.value);
                }}
                validationCheck={
                  formik.touched.badge_en
                    ? formik.errors.badge_en
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.badge_en && formik.errors.badge_en
                    ? formik.errors.badge_en
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                className='text-dir-rtl'
                type='text'
                labelText={`${i18n.t('pages.organization.badge_ar')}`}
                placeholder={`${i18n.t('pages.organization.badge_ar')}`}
                textValue={formik.values.badge_ar}
                onBlur={(e: any) => {
                  formik.setFieldTouched('badge_ar', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('badge_ar', e.target.value);
                }}
                validationCheck={
                  formik.touched.badge_ar
                    ? formik.errors.badge_ar
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.badge_ar && formik.errors.badge_ar
                    ? formik.errors.badge_ar
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col
              md={6}
              className={`mt-4 text-align-start ${
                currentLang === 'ar' ? 'order-2' : ''
              }`}
            >
              <RichBox
                labelText={`${i18n.t('pages.organization.brief_en')}`}
                initialContent={formik.values.brief_en}
                onChange={(content: any) => {
                  formik.setFieldTouched('brief_en', true);
                  formik.setFieldValue('brief_en', content);
                }}
                errorMessage={
                  formik.touched.brief_en && formik.errors.brief_en
                    ? formik.errors.brief_en
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <RichBox
                labelText={`${i18n.t('pages.organization.brief_ar')}`}
                initialContent={formik.values.brief_ar}
                onChange={(content: any) => {
                  formik.setFieldTouched('brief_ar', true);
                  formik.setFieldValue('brief_ar', content);
                }}
                errorMessage={
                  formik.touched.brief_ar && formik.errors.brief_ar
                    ? formik.errors.brief_ar
                    : undefined
                }
              />
            </Col>
          </Row>
          {!Constants.serviceTypeCompanyIds.includes(
            formik.values.serviceType_id.value
          ) && (
            <Row className={'me-0 ms-0'}>
              <Col md={6} className='mt-4 text-align-start'>
                <Input
                  type='text'
                  labelText={`${i18n.t('pages.organization.wage')}`}
                  placeholder={`${i18n.t('pages.organization.wage')}`}
                  textValue={formik.values.wage?.toString()}
                  onBlur={(e: any) => {
                    formik.setFieldTouched('wage', true);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('wage', e.target.value);
                  }}
                  validationCheck={
                    formik.touched.wage
                      ? formik.errors.wage
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.wage && formik.errors.wage
                      ? formik.errors.wage
                      : undefined
                  }
                />
              </Col>
              <Col md={6} className='mt-4 text-align-start'></Col>
            </Row>
          )}
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-3 d-flex justify-content-center'>
              <TitleHeader
                type='title'
                text={i18n.t('pages.organization.social')}
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t('global.facebookUrl')}`}
                placeholder={`${i18n.t('global.facebookUrl')}`}
                textValue={formik.values.facebook_url}
                onBlur={(e: any) => {
                  formik.setFieldTouched('facebook_url', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('facebook_url', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.facebook_url
                    ? formik.errors.facebook_url
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.facebook_url && formik.errors.facebook_url
                    ? formik.errors.facebook_url
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t('global.twitterUrl')}`}
                placeholder={`${i18n.t('global.twitterUrl')}`}
                textValue={formik.values.twitter_url}
                onBlur={(e: any) => {
                  formik.setFieldTouched('twitter_url', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('twitter_url', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.twitter_url
                    ? formik.errors.twitter_url
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.twitter_url && formik.errors.twitter_url
                    ? formik.errors.twitter_url
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t('global.linkedInUrl')}`}
                placeholder={`${i18n.t('global.linkedInUrl')}`}
                textValue={formik.values.linkedin_url}
                onBlur={(e: any) => {
                  formik.setFieldTouched('linkedin_url', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('linkedin_url', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.linkedin_url
                    ? formik.errors.linkedin_url
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.linkedin_url && formik.errors.linkedin_url
                    ? formik.errors.linkedin_url
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              {Constants.serviceTypeCompanyIds.includes(
                formik.values.serviceType_id.value
              ) ? (
                <Input
                  type='text'
                  labelText={`${i18n.t('global.officialWebsiteUrl')}`}
                  placeholder={`${i18n.t('global.officialWebsiteUrl')}`}
                  textValue={formik.values.official_website_url}
                  onBlur={(e: any) => {
                    formik.setFieldTouched('official_website_url', true);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue(
                      'official_website_url',
                      e.currentTarget.value
                    );
                  }}
                  validationCheck={
                    formik.touched.official_website_url
                      ? formik.errors.official_website_url
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.official_website_url &&
                    formik.errors.official_website_url
                      ? formik.errors.official_website_url
                      : undefined
                  }
                />
              ) : (
                <Input
                  type='text'
                  labelText={`${i18n.t('global.contactEmail')}`}
                  placeholder={`${i18n.t('global.contactEmail')}`}
                  textValue={formik.values.contact_email}
                  onBlur={(e: any) => {
                    formik.setFieldTouched('contact_email', true);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue(
                      'contact_email',
                      e.currentTarget.value
                    );
                  }}
                  validationCheck={
                    formik.touched.contact_email
                      ? formik.errors.contact_email
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.contact_email && formik.errors.contact_email
                      ? formik.errors.contact_email
                      : undefined
                  }
                />
              )}
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-3 d-flex justify-content-center'>
              <TitleHeader
                type='title'
                text={i18n.t('pages.organization.contents')}
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Button
                styleType='light'
                text={`${i18n.t('global.keywords')}`}
                onClick={() => setIsKeywordsModalOpen(true)}
              />
              {keywordsHasErrors &&
                renderError(i18n.t('global.keywordsError') as string)}
            </Col>
            {!Constants.serviceTypeCompanyIds.includes(
              formik.values.serviceType_id.value
            ) && (
              <Col md={6} className='mt-4 text-align-start'>
                <Button
                  styleType='light'
                  text={`${i18n.t('global.skills')}`}
                  onClick={() => setIsSkillsModalOpen(true)}
                />
                {skillsHasErrors &&
                  renderError(i18n.t('global.skillsError') as string)}
              </Col>
            )}
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-5 d-flex'>
              <Button
                styleType='light'
                text={i18n.t('global.forms.submit')}
                disabled={
                  Object.keys(formik.errors).length > 0 ||
                  Object.keys(formik.touched).length === 0
                }
                onClick={async () => {
                  formik.submitForm();
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <PortalModal
        isOpen={isKeywordsModalOpen}
        centered
        className={'align-items-center p-3'}
      >
        <Container fluid>
          {formik.values.keywords.map((keyword: any, i) => (
            <Row key={i} className={'me-0 ms-0'}>
              <Col md={1} className='d-flex align-items-start'>
                <Button
                  className={`${'mt-5'} px-3 py-0`}
                  styleType='dark'
                  text={'-'}
                  onClick={() => {
                    const updateKeywords = formik.values.keywords.filter(
                      (k: any) => k.key !== keyword.key
                    );
                    formik.setFieldValue('keywords', updateKeywords);
                  }}
                />
              </Col>
              <Col md={10} className='mt-2'>
                <Input
                  type='text'
                  labelText={`${i18n.t('global.enterKeyword')}`}
                  placeholder={`${i18n.t('global.enterKeyword')}`}
                  textValue={
                    (
                      formik.values.keywords.find(
                        (k: any, i) => k.key == keyword.key
                      ) as any
                    )?.text
                  }
                  onBlur={(e: any) => {
                    formik.setFieldTouched('keywords', true);
                  }}
                  onChange={(e: any) => {
                    const updateKeywords = formik.values.keywords.map(
                      (k: any) =>
                        k.key === keyword.key
                          ? { ...k, text: e.currentTarget.value }
                          : k
                    );
                    formik.setFieldValue('keywords', updateKeywords);
                  }}
                  validationCheck={
                    formik.touched.keywords
                      ? (formik.errors as any)[`keywords[${i}].text`]
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.keywords &&
                    (formik.errors as any)[`keywords[${i}].text`]
                      ? (formik.errors as any)[`keywords[${i}].text`]
                      : undefined
                  }
                />
              </Col>
            </Row>
          ))}
          <Row className={'mt-3 me-0 ms-0'}>
            <Col md={12} className='mt-2 d-flex justify-content-center'>
              <Button
                className='px-4'
                styleType='light'
                text={'+'}
                onClick={() => {
                  formik.setFieldValue('keywords', [
                    ...formik.values.keywords,
                    { key: randomStr(), text: '' },
                  ]);
                }}
              />
            </Col>
          </Row>
          <Row className={'mt-3 me-0 ms-0'}>
            <Col md={1} className='mt-2 d-flex'>
              <Button
                styleType='dark'
                text={`${i18n.t('global.close')}`}
                onClick={() => setIsKeywordsModalOpen(false)}
              />
            </Col>
            <Col md={2} className='mt-2 d-flex'>
              <Button
                className='px-4'
                styleType='light'
                text={`${i18n.t('global.done')}`}
                onClick={() => setIsKeywordsModalOpen(false)}
              />
            </Col>
          </Row>
        </Container>
      </PortalModal>
      <PortalModal
        isOpen={isSkillsModalOpen}
        centered
        className={'align-items-center p-3'}
      >
        <Container fluid>
          {formik.values.skills.map((skill: any, i) => (
            <Row key={i} className={'me-0 ms-0'}>
              <Col md={1} className='d-flex align-items-start'>
                <Button
                  className={`${'mt-5'} px-3 py-0`}
                  styleType='dark'
                  text={'-'}
                  onClick={() => {
                    const updateSkills = formik.values.skills.filter(
                      (k: any) => k.key !== skill.key
                    );
                    formik.setFieldValue('skills', updateSkills);
                  }}
                />
              </Col>
              <Col md={10} className='mt-2'>
                <Input
                  type='text'
                  labelText={`${i18n.t('global.enterSkill')}`}
                  placeholder={`${i18n.t('global.enterSkill')}`}
                  textValue={
                    (
                      formik.values.skills.find(
                        (k: any, i) => k.key == skill.key
                      ) as any
                    )?.text
                  }
                  onBlur={(e: any) => {
                    formik.setFieldTouched('skills', true);
                  }}
                  onChange={(e: any) => {
                    const updateSkills = formik.values.skills.map((k: any) =>
                      k.key === skill.key
                        ? { ...k, text: e.currentTarget.value }
                        : k
                    );
                    formik.setFieldValue('skills', updateSkills);
                  }}
                  validationCheck={
                    formik.touched.skills
                      ? (formik.errors as any)[`skills[${i}].text`]
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.skills &&
                    (formik.errors as any)[`skills[${i}].text`]
                      ? (formik.errors as any)[`skills[${i}].text`]
                      : undefined
                  }
                />
              </Col>
            </Row>
          ))}
          <Row className={'mt-3 me-0 ms-0'}>
            <Col md={12} className='mt-2 d-flex justify-content-center'>
              <Button
                className='px-4'
                styleType='light'
                text={'+'}
                onClick={() => {
                  formik.setFieldValue('skills', [
                    ...formik.values.skills,
                    { key: randomStr(), text: '' },
                  ]);
                }}
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={1} className='mt-2 d-flex'>
              <Button
                styleType='dark'
                text={`${i18n.t('global.close')}`}
                onClick={() => setIsSkillsModalOpen(false)}
              />
            </Col>
            <Col md={2} className='mt-2 d-flex'>
              <Button
                className='px-4'
                styleType='light'
                text={`${i18n.t('global.done')}`}
                onClick={() => setIsSkillsModalOpen(false)}
              />
            </Col>
          </Row>
        </Container>
      </PortalModal>
    </Container>
  );
};

OrganizationForm.displayName = 'OrganizationForm';
export default OrganizationForm;
