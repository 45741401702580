/* eslint-disable camelcase */
import axios, { AxiosInstance } from 'axios';
import { getApiErrorMessage } from '../../src/helpers/mappers';

export const getServiceTypesList =
  (axiosInstance: AxiosInstance) => async () => {
    try {
      const response = await axiosInstance.get('/serviceTypes', {
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
        },
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };
