enum Permission {
  VIEW_ADMIN = 'VIEW_ADMIN',
  CREATE_ADMIN = 'CREATE_ADMIN',
  EDIT_ADMIN = 'EDIT_ADMIN',
  DELETE_ADMIN = 'DELETE_ADMIN',
  VIEW_STUDENT = 'VIEW_STUDENT',
  CREATE_STUDENT = 'CREATE_STUDENT',
  EDIT_STUDENT = 'EDIT_STUDENT',
  DELETE_STUDENT = 'DELETE_STUDENT',
  VIEW_ORGANIZATION = 'VIEW_ORGANIZATION',
  CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
  EDIT_ORGANIZATION = 'EDIT_ORGANIZATION',
  DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',
  VIEW_CATEGORIES = 'VIEW_CATEGORIES',
  CREATE_CATEGORIES = 'CREATE_CATEGORIES',
  EDIT_CATEGORIES = 'EDIT_CATEGORIES',
  DELETE_CATEGORIES = 'DELETE_CATEGORIES',
  VIEW_SUB_CATEGORIES = 'VIEW_SUB_CATEGORIES',
  CREATE_SUB_CATEGORIES = 'CREATE_SUB_CATEGORIES',
  EDIT_SUB_CATEGORIES = 'EDIT_SUB_CATEGORIES',
  DELETE_SUB_CATEGORIES = 'DELETE_SUB_CATEGORIES',
  VIEW_COURSES = 'VIEW_COURSES',
  CREATE_COURSES = 'CREATE_COURSES',
  EDIT_COURSES = 'EDIT_COURSES',
  DELETE_COURSES = 'DELETE_COURSES',
  VIEW_BAGS = 'VIEW_BAGS',
  CREATE_BAGS = 'CREATE_BAGS',
  EDIT_BAGS = 'EDIT_BAGS',
  DELETE_BAGS = 'DELETE_BAGS',
  VIEW_CONSULTATION_REQUEST_VIEW = 'VIEW_CONSULTATION_REQUEST_VIEW',
  DELETE_CONSULTATION_REQUEST = 'DELETE_CONSULTATION_REQUEST',
  VIEW_UPDATE_REQUEST_VIEW = 'VIEW_UPDATE_REQUEST_VIEW',
  DELETE_UPDATE_REQUEST = 'DELETE_UPDATE_REQUEST',
  VIEW_HOME_PAGE = 'VIEW_HOME_PAGE',
  VIEW_SIGN_IN_PAGE = 'VIEW_SIGN_IN_PAGE',
  VIEW_COURSES_PAGE = 'VIEW_COURSES_PAGE',
  VIEW_COURSE_PAGE = 'VIEW_COURSE_PAGE',
  VIEW_BAGS_PAGE = 'VIEW_BAGS_PAGE',
  VIEW_BAG_PAGE = 'VIEW_BAG_PAGE',
  VIEW_COMPANIES_PAGE = 'VIEW_COMPANIES_PAGE',
  VIEW_COMPANY_PAGE = 'VIEW_COMPANY_PAGE',
  VIEW_COORDINATORS_PAGE = 'VIEW_COORDINATORS_PAGE',
  VIEW_COORDINATOR_PAGE = 'VIEW_COORDINATOR_PAGE',
  VIEW_MARKETERS_PAGE = 'VIEW_MARKETERS_PAGE',
  VIEW_MARKETER_PAGE = 'VIEW_MARKETER_PAGE',
  VIEW_TRAINERS_PAGE = 'VIEW_TRAINERS_PAGE',
  VIEW_TRAINER_PAGE = 'VIEW_TRAINER_PAGE',
  VIEW_SEARCH_PAGE = 'VIEW_SEARCH_PAGE',
}

export default Permission;
