import Constants from '../constants';
import { AxiosInstance } from 'axios';
import { getApiErrorMessage } from '../helpers/mappers';

let loadAdminsController: any;

export const fetchAdmins =
  (axiosInstance: AxiosInstance) =>
  (
    page?: string,
    search?: string,
    orderby?: { [key: string]: 'ASC' | 'DESC' }[]
  ) => {
    try {
      if (loadAdminsController) loadAdminsController.abort();
      loadAdminsController = new AbortController();
      const response = axiosInstance.get('/admins', {
        params: { page, search, orderby },
        signal: loadAdminsController.signal,
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const fetchAdmin =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.get(`/admins/${id}`);
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const fetchMyProfile = (axiosInstance: AxiosInstance) => async () => {
  try {
    const response = await axiosInstance.get('/admins/my-profile');
    return response;
  } catch (err: any) {
    return getApiErrorMessage(err);
  }
};

export const createAdmin =
  (axiosInstance: AxiosInstance) => async (admin: any) => {
    try {
      const response = await axiosInstance.post(
        '/admins',
        {
          firstName: admin.firstName,
          lastName: admin.lastName,
          email: admin.email,
          password: admin.password,
          phone_code: admin.code,
          phone_number: admin.phoneNumber,
          permissions: admin.permissions,
          profile_image: admin.photo,
        },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updateAdmin =
  (axiosInstance: AxiosInstance) => async (admin: any, id: string) => {
    try {
      let adminData: any = {
        firstName: admin.firstName,
        lastName: admin.lastName,
        email: admin.email,
        password: admin.password,
        phone_code: admin.code,
        phone_number: admin.phoneNumber,
        permissions: admin.permissions,
      };
      if (admin.photo && typeof admin.photo !== 'string') {
        adminData = {
          ...adminData,
          profile_image: admin.photo,
        };
      }
      const response = await axiosInstance.post(`/admins/${id}`, adminData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updatePartialAdmin =
  (axiosInstance: AxiosInstance) => async (admin: any, id: string) => {
    try {
      let adminData: any = {
        firstName: admin.firstName,
        lastName: admin.lastName,
        email: admin.email,
      };
      if (admin.photo && typeof admin.photo !== 'string') {
        adminData = {
          ...adminData,
          profile_image: admin.photo,
        };
      }
      const response = await axiosInstance.post(
        `/admins/${id}/partial`,
        adminData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const deleteAdmin =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.delete(`/admins/${id}`);
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };
