import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import styles from './index.module.scss';
import ViewConsultationRequestContainer from '../../containers/consultation_requests/view';
import { useParams } from 'react-router-dom';
import i18n from '../../i18n';
import TitleHeader from '../../components/titleHeader';
import { useCanAccess } from '../../hooks/canAccess';
import Permission from '../../enum/permission.enum';

interface IViewConsultationRequestPageProps {
  data?: any;
}

export default function PageViewConsultationRequest(
  props: IViewConsultationRequestPageProps
) {
  const { id } = useParams();
  const { canAccess } = useCanAccess();
  if (
    !canAccess({
      permissions: [Permission.VIEW_CONSULTATION_REQUEST_VIEW],
    })
  ) {
    return (
      <Container fluid={true} className={'p-2'}>
        <Row className={'m-0'}>
          <Col sm={12} md={12} className={'d-flex justify-content-center'}>
            <TitleHeader type='title' text='Page not found' />
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Container fluid={true} className={'p-2'}>
      <Row className={'m-0'}>
        <Col sm={6} md={6} className='p-0'>
          <TitleHeader
            type='subtitle'
            text={i18n.t('pages.consultationRequests.form-view-title')}
          />
        </Col>
      </Row>
      <Row className={'m-0 mt-3'}>
        <Col className='p-0'>
          <ViewConsultationRequestContainer />
        </Col>
      </Row>
    </Container>
  );
}
