import React, { useEffect, useState } from 'react';
import { IHeaderColumn, IRow } from '../../components/dataTable';
import { handleSortTableColumn } from '../../helpers/logic';
import Paragraph from '../../components/paragraph';
import Input from '../../components/input';
import { Dropdown } from 'antd';
import { AiOutlineMore } from 'react-icons/ai';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import i18n from '../../i18n';
import Constants from '../../constants';
import Permission from '../../enum/permission.enum';

export const headerColumnsBuilder = (
  searchParamsOrderby: { columnName: string; sort: 'ASC' | 'DESC' }[],
  searchParams: any,
  setSearchParams: (val: any) => void
): IHeaderColumn[] => {
  let columns: IHeaderColumn[] = [
    {
      name: 'id',
      displayedName: 'Id',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'id'));
      },
    },
    {
      name: 'email',
      displayedName: 'Email',
    },
    {
      name: 'type',
      displayedName: 'Type',
    },
    {
      name: 'serviceType_id',
      displayedName: 'Service type',
    },
    {
      name: 'created_at',
      displayedName: 'Created at',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'created_at'));
      },
    },
    {
      name: 'actions',
      displayedName: 'Actions',
    },
  ];

  searchParamsOrderby.forEach((val, i) => {
    columns = columns.map((column) => {
      return {
        ...column,
        sorted: column.name === val.columnName ? val.sort : column.sorted,
      };
    });
  });
  return columns;
};

export const rowsBuilder = (
  consultationRequests: any[],
  loadedConsultationRequests: any[],
  getLocalProp: any,
  setShowDeleteAlert: (val: { show: boolean; id: string }) => void,
  canAccess: any,
  axiosInstance: any
): IRow[] => {
  return consultationRequests.map((val, i) => ({
    dataObj: val,
    props: [
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.id}</span>;
        },
        onCellClick(e: any, rowValue: any) {
          location.href =
            i18n.language === Constants.defaultLang
              ? `/consultation_requests/${rowValue.id}`
              : `/${i18n.language}/consultation_requests/${rowValue.id}`;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.email}</span>;
        },
        onCellClick(e: any, rowValue: any) {
          location.href =
            i18n.language === Constants.defaultLang
              ? `/consultation_requests/${rowValue.id}`
              : `/${i18n.language}/consultation_requests/${rowValue.id}`;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.type}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{getLocalProp(rowValue, 'name', '')}</span>;
        },
        onCellClick(e: any, rowValue: any) {
          location.href =
            i18n.language === Constants.defaultLang
              ? `/consultation_requests/${rowValue.id}`
              : `/${i18n.language}/consultation_requests/${rowValue.id}`;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.created_at}</span>;
        },
        onCellClick(e: any, rowValue: any) {
          location.href =
            i18n.language === Constants.defaultLang
              ? `/consultation_requests/${rowValue.id}`
              : `/${i18n.language}/consultation_requests/${rowValue.id}`;
        },
      },
      {
        loadContent: (rowValue) => {
          const actionItems: any[] = [];
          if (
            canAccess({
              permissions: [Permission.DELETE_CONSULTATION_REQUEST],
            })
          ) {
            actionItems.push({
              label: 'Delete',
              key: '2',
              icon: <DeleteOutlined />,
              onClick: () => {
                setShowDeleteAlert({ show: true, id: rowValue.id });
              },
            });
          }
          return (
            <span>
              <Dropdown
                menu={{
                  items: actionItems,
                }}
              >
                <AiOutlineMore size={30} />
              </Dropdown>
            </span>
          );
        },
      },
    ],
  }));
};
