import * as Yup from 'yup';

export const keywords = (requiredMsg: string, minMsg: string, maxMsg: string) =>
  Yup.array().of(
    Yup.object({
      text: Yup.string().min(3, minMsg).max(100, maxMsg).required(requiredMsg),
    })
  );

export const contents = (requiredMsg: string, minMsg: string, maxMsg: string) =>
  Yup.array().of(
    Yup.object({
      text: Yup.string().min(3, minMsg).max(100, maxMsg).required(requiredMsg),
    })
  );

export const categories = (requiredMsg: string) =>
  Yup.array().min(1, requiredMsg);

export const ownerId = (requiredMsg: string) =>
  Yup.mixed().test('organization_id', requiredMsg, (val: any) => {
    let isValid = true;
    if (val.value == 0) isValid = false;
    return isValid;
  });
