import * as Yup from 'yup';
import i18n from '../../i18n';
import {
  commercialRegister,
  communicationOfficer,
  coursesDepartments,
  licenseNumber,
  organizationName,
  serviceOwnerType,
  wage,
} from '../../validation/organization';
import {
  countryCode,
  email,
  password,
  passwordConfirmation,
  phoneNumber,
  longName,
} from '../../validation';
import { contents, keywords } from '../../validation/courses';

export const organizationSchema = (isEdit?: boolean) => {
  let schemaObj: any = {
    name: longName(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.longNameMax')
    ),
    license_number: licenseNumber(i18n.t('global.validation.required')),
    commercial_registration_number: commercialRegister(
      i18n.t('global.validation.required')
    ),
    communication_officer: communicationOfficer(
      i18n.t('global.validation.required')
    ),
    courseTypes: coursesDepartments(i18n.t('global.validation.required')),
    email: email(
      i18n.t('global.validation.email'),
      i18n.t('global.validation.required')
    ),
    phone_code: countryCode(i18n.t('global.validation.countryCode')),
    phone_number: phoneNumber(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.phoneNumber')
    ),
    keywords: keywords(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.nameMax')
    ),
    skills: contents(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.nameMax')
    ),
    wage: wage(i18n.t('global.validation.numeric')),
  };
  if (!isEdit) {
    schemaObj = {
      ...schemaObj,
      serviceType_id: serviceOwnerType(i18n.t('global.validation.required')),
      password: password(
        i18n.t('global.validation.required'),
        i18n.t('global.validation.passwordMin'),
        i18n.t('global.validation.passwordMax')
      ),
      passwordConfirmation: passwordConfirmation(
        i18n.t('global.validation.passwordMatch'),
        i18n.t('global.validation.required')
      ),
    };
  }
  return Yup.object().shape(schemaObj);
};
