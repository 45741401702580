import React, { useEffect, useState } from 'react';
import { IHeaderColumn, IRow } from '../../components/dataTable';
import { handleSortTableColumn } from '../../helpers/logic';
import Paragraph from '../../components/paragraph';
import Input from '../../components/input';
import { Dropdown } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { AiOutlineMore } from 'react-icons/ai';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { updatePartialAdmin } from '../../api/admins';
import Permission from '../../enum/permission.enum';
import Role from '../../enum/role.enum';

export const headerColumnsBuilder = (
  searchParamsOrderby: { columnName: string; sort: 'ASC' | 'DESC' }[],
  searchParams: any,
  setSearchParams: (val: any) => void
): IHeaderColumn[] => {
  let columns: IHeaderColumn[] = [
    {
      name: 'id',
      displayedName: 'Id',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'id'));
      },
    },
    {
      name: 'first_name',
      displayedName: 'First name',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'first_name'));
      },
    },
    {
      name: 'last_name',
      displayedName: 'Last name',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'last_name'));
      },
    },
    {
      name: 'email',
      displayedName: 'Email',
    },
    {
      name: 'created_at',
      displayedName: 'Created at',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'created_at'));
      },
    },
    {
      name: 'updated_at',
      displayedName: 'Updated at',
    },
    {
      name: 'actions',
      displayedName: 'Actions',
    },
  ];

  searchParamsOrderby.forEach((val, i) => {
    columns = columns.map((column) => {
      return {
        ...column,
        sorted: column.name === val.columnName ? val.sort : column.sorted,
      };
    });
  });
  return columns;
};

export const rowsBuilder = (
  admins: any[],
  loadedAdmins: any[],
  setLoadedAdmins: (val: any) => void,
  redirectReload: (route: string) => void,
  setShowDeleteAlert: (val: { show: boolean; id: string }) => void,
  canAccess: any,
  axiosInstance: any
): IRow[] => {
  return admins.map((val, i) => ({
    dataObj: val,
    props: [
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.id}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return (
            <Paragraph
              editable={
                <Input
                  type='text'
                  value={rowValue.first_name}
                  onChange={(e) => {
                    setLoadedAdmins(
                      loadedAdmins.map((admin, i) => {
                        return admin.id == rowValue.id
                          ? { ...admin, first_name: e.target.value }
                          : admin;
                      })
                    );
                  }}
                />
              }
              onLeaveEdit={() => {
                handleEdit(
                  axiosInstance,
                  {
                    firstName: rowValue.first_name,
                    lastName: rowValue.last_name,
                    email: rowValue.email,
                  },
                  rowValue.id
                );
              }}
            >
              {rowValue.first_name}
            </Paragraph>
          );
        },
      },
      {
        loadContent: (rowValue) => {
          return (
            <Paragraph
              editable={
                <Input
                  type='text'
                  value={rowValue.last_name}
                  onChange={(e) => {
                    setLoadedAdmins(
                      loadedAdmins.map((admin, i) => {
                        return admin.id == rowValue.id
                          ? { ...admin, last_name: e.target.value }
                          : admin;
                      })
                    );
                  }}
                />
              }
              onLeaveEdit={() => {
                handleEdit(
                  axiosInstance,
                  {
                    firstName: rowValue.first_name,
                    lastName: rowValue.last_name,
                    email: rowValue.email,
                  },
                  rowValue.id
                );
              }}
            >
              {rowValue.last_name}
            </Paragraph>
          );
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.email}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.created_at}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.updated_at}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          const actionItems: any[] = [];
          if (
            canAccess({
              roles: [Role.SuperAdmin],
              permissions: [Permission.EDIT_ADMIN],
            })
          ) {
            actionItems.push({
              label: 'Edit',
              key: '1',
              icon: <EditOutlined />,
              onClick: () => {
                redirectReload(`admins/${rowValue.id}`);
              },
            });
          }
          if (
            canAccess({
              roles: [Role.SuperAdmin],
              permissions: [Permission.DELETE_ADMIN],
            })
          ) {
            actionItems.push({
              label: 'Delete',
              key: '2',
              icon: <DeleteOutlined />,
              onClick: () => {
                setShowDeleteAlert({ show: true, id: rowValue.id });
              },
            });
          }
          return (
            <span>
              <Dropdown
                menu={{
                  items: actionItems,
                }}
              >
                <AiOutlineMore size={30} />
              </Dropdown>
            </span>
          );
        },
      },
    ],
  }));
};

const handleEdit = async (axiosInstance: any, values: any, id: string) => {
  const results: any = await updatePartialAdmin(axiosInstance)(values, id);
  if (results.error) {
    const toastMsg = i18n.t('global.entityUpdateFailed', {
      entity: i18n.t('global.entities.admin'),
    });
    toast.error(toastMsg, {
      position: i18n.language == 'ar' ? 'top-left' : 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  } else {
    const toastMsg = i18n.t('global.entityUpdatedSuccessfully', {
      entity: i18n.t('global.entities.admin'),
    });
    toast.success(toastMsg, {
      position: i18n.language == 'ar' ? 'top-left' : 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }
};
