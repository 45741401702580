import Constants from '../constants';
import { AxiosInstance } from 'axios';
import { getApiErrorMessage } from '../helpers/mappers';

export const fetchCategories = (axiosInstance: AxiosInstance) => () => {
  try {
    const response = axiosInstance.get('categories');
    return response;
  } catch (err: any) {
    return getApiErrorMessage(err);
  }
};

export const fetchSubCategories = (axiosInstance: AxiosInstance) => () => {
  try {
    const response = axiosInstance.get('sub_categories');
    return response;
  } catch (err: any) {
    return getApiErrorMessage(err);
  }
};

let loadCategoriesController: any;

export const fetchAllCategories =
  (axiosInstance: AxiosInstance) =>
  (
    page?: string,
    search?: string,
    orderby?: { [key: string]: 'ASC' | 'DESC' }[]
  ) => {
    try {
      if (loadCategoriesController) loadCategoriesController.abort();
      loadCategoriesController = new AbortController();
      const response = axiosInstance.get('/dashboard_categories', {
        params: { page, search, orderby },
        signal: loadCategoriesController.signal,
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const fetchCategory =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.get(`/dashboard_categories/${id}`);
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const createCategory =
  (axiosInstance: AxiosInstance) => async (category: any) => {
    try {
      const response = await axiosInstance.post('/dashboard_categories', {
        name_en: category.name_en,
        name_ar: category.name_ar,
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updateCategory =
  (axiosInstance: AxiosInstance) => async (category: any, id: string) => {
    try {
      const categoryData: any = {
        name_en: category.name_en,
        name_ar: category.name_ar,
      };
      const response = await axiosInstance.post(
        `/dashboard_categories/${id}`,
        categoryData
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updatePartialCategory =
  (axiosInstance: AxiosInstance) => async (category: any, id: string) => {
    try {
      const categoryData: any = {
        name_en: category.name_en,
        name_ar: category.name_ar,
      };
      const response = await axiosInstance.post(
        `/dashboard_categories/${id}/partial`,
        categoryData
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const deleteCategory =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.delete(
        `/dashboard_categories/${id}`
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

let loadSubCategoriesController: any;

export const fetchAllSubCategories =
  (axiosInstance: AxiosInstance) =>
  (
    page?: string,
    search?: string,
    orderby?: { [key: string]: 'ASC' | 'DESC' }[]
  ) => {
    try {
      if (loadSubCategoriesController) loadSubCategoriesController.abort();
      loadSubCategoriesController = new AbortController();
      const response = axiosInstance.get('/dashboard_sub_categories', {
        params: { page, search, orderby },
        signal: loadSubCategoriesController.signal,
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const fetchSubCategory =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.get(
        `/dashboard_sub_categories/${id}`
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const createSubCategory =
  (axiosInstance: AxiosInstance) => async (subCategory: any) => {
    try {
      const response = await axiosInstance.post('/dashboard_sub_categories', {
        category_id: subCategory.category_id,
        name_en: subCategory.name_en,
        name_ar: subCategory.name_ar,
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updateSubCategory =
  (axiosInstance: AxiosInstance) => async (subCategory: any, id: string) => {
    try {
      const subCategoryData: any = {
        category_id: subCategory.category_id,
        name_en: subCategory.name_en,
        name_ar: subCategory.name_ar,
      };
      const response = await axiosInstance.post(
        `/dashboard_sub_categories/${id}`,
        subCategoryData
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updatePartialSubCategory =
  (axiosInstance: AxiosInstance) => async (subCategory: any, id: string) => {
    try {
      const subCategoryData: any = {
        name_en: subCategory.name_en,
        name_ar: subCategory.name_ar,
      };
      const response = await axiosInstance.post(
        `/dashboard_sub_categories/${id}/partial`,
        subCategoryData
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const deleteSubCategory =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.delete(
        `/dashboard_sub_categories/${id}`
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };
