import * as Yup from 'yup';
import { longName } from '../../validation';
import i18n from '../../i18n';

export const categorySchema = () =>
  Yup.object().shape({
    name_en: longName(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.longNameMax')
    ),
    name_ar: longName(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.longNameMax')
    ),
  });
