import * as Yup from 'yup';
import {
  countryCode,
  email,
  name,
  password,
  passwordConfirmation,
  phoneNumber,
} from '../../validation';
import i18n from '../../i18n';

export const adminSchema = (isEdit?: boolean) => {
  let schemaObj: any = {
    firstName: name(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.nameMax')
    ),
    lastName: name(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.nameMax')
    ),
    email: email(
      i18n.t('global.validation.email'),
      i18n.t('global.validation.required')
    ),
    code: countryCode(i18n.t('global.validation.countryCode')),
    phoneNumber: phoneNumber(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.phoneNumber')
    ),
  };
  if (!isEdit) {
    schemaObj = {
      ...schemaObj,
      password: password(
        i18n.t('global.validation.required'),
        i18n.t('global.validation.passwordMin'),
        i18n.t('global.validation.passwordMax')
      ),
      passwordConfirmation: passwordConfirmation(
        i18n.t('global.validation.passwordMatch'),
        i18n.t('global.validation.required')
      ),
    };
  }
  return Yup.object().shape(schemaObj);
};
