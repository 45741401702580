import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import PageStudents from '../pages/students';
import Layout from '../layout';
import Page404 from '../pages/404';
import PageSignIn from '../pages/sign-in';
import PageDashboard from '../pages/dashboard';
import PageProfile from '../pages/profile';
import PageAddEditStudent from '../pages/students/addEdit';
import PageAdmins from '../pages/admins';
import PageAddEditAdmin from '../pages/admins/addEdit';
import PageOrganizations from '../pages/organizations';
import PageAddEditOrganization from '../pages/organizations/addEdit';
import PageCourses from '../pages/courses';
import PageAddEditCourse from '../pages/courses/addEdit';
import PageBags from '../pages/bags';
import PageAddEditBag from '../pages/bags/addEdit';
import PageViewConsultationRequest from '../pages/consultation_requests/view';
import PageConsultationRequests from '../pages/consultation_requests';
import PageAddEditCategory from '../pages/categories/addEdit';
import PageCategories from '../pages/categories';
import PageSubCategories from '../pages/sub_categories';
import PageAddEditSubCategory from '../pages/sub_categories/addEdit';
import PageWebsiteHome from '../pages/website-home';
import PageWebsiteSignIn from '../pages/website-signin';
import PageWebsiteCoursesListing from '../pages/website-courses-listing';
import PageWebsiteCourseDetails from '../pages/website-course-details';
import PageWebsiteBagsListing from '../pages/website-bags-listing';
import PageWebsiteBagDetails from '../pages/website-bag-details';
import PageWebsiteCompaniesListing from '../pages/website-companies-listing';
import PageWebsiteCompanyDetails from '../pages/website-company-details';
import PageWebsiteCoordinatorsListing from '../pages/website-coordinators-listing';
import PageWebsiteCoordinatorDetails from '../pages/website-coordinator-details';
import PageWebsiteMarketersListing from '../pages/website-marketers-listing';
import PageWebsiteMarketerDetails from '../pages/website-marketer-details';
import PageWebsiteTrainersListing from '../pages/website-trainers-listing';
import PageWebsiteTrainerDetails from '../pages/website-trainer-details';
import PageOrganizationUpdateRequests from '../pages/organization_update_requests';
import PageViewOrganizationUpdateRequest from '../pages/organization_update_requests/view';
import PageWebsiteSearch from '../pages/website-search';

export default function RoutesComponent() {
  const location = useLocation();

  const getActualPath = () => {
    let path = '/';
    if (location.pathname.includes('/en/')) path = 'en';
    else if (location.pathname.includes('/ar/')) path = 'ar';
    return path;
  };

  const [actuallPath, setActuallPath] = useState(getActualPath());

  useEffect(() => {
    setActuallPath(getActualPath());
  }, [location.pathname]);
  return (
    <Routes>
      <Route path={actuallPath} Component={Layout}>
        <Route path='' element={<PageDashboard />} />
        <Route path='dashboard' element={<PageDashboard />} />
        <Route path='my-profile' element={<PageProfile />} />
        <Route path='admins' element={<PageAdmins />} />
        <Route path='admins/add' element={<PageAddEditAdmin />} />
        <Route path='admins/:id' element={<PageAddEditAdmin />} />
        <Route path='students' element={<PageStudents />} />
        <Route path='students/add' element={<PageAddEditStudent />} />
        <Route path='students/:id' element={<PageAddEditStudent />} />
        <Route path='organizations' element={<PageOrganizations />} />
        <Route path='organizations/add' element={<PageAddEditOrganization />} />
        <Route path='organizations/:id' element={<PageAddEditOrganization />} />
        <Route path='courses' element={<PageCourses />} />
        <Route path='courses/add' element={<PageAddEditCourse />} />
        <Route path='courses/:id' element={<PageAddEditCourse />} />
        <Route path='bags' element={<PageBags />} />
        <Route path='bags/add' element={<PageAddEditBag />} />
        <Route path='bags/:id' element={<PageAddEditBag />} />
        <Route
          path='consultation_requests'
          element={<PageConsultationRequests />}
        />
        <Route
          path='consultation_requests/:id'
          element={<PageViewConsultationRequest />}
        />
        <Route
          path='organization_update_requests'
          element={<PageOrganizationUpdateRequests />}
        />
        <Route
          path='organization_update_requests/:id'
          element={<PageViewOrganizationUpdateRequest />}
        />
        <Route path='categories' element={<PageCategories />} />
        <Route path='categories/add' element={<PageAddEditCategory />} />
        <Route path='categories/:id' element={<PageAddEditCategory />} />
        <Route path='sub_categories' element={<PageSubCategories />} />
        <Route path='sub_categories/add' element={<PageAddEditSubCategory />} />
        <Route path='sub_categories/:id' element={<PageAddEditSubCategory />} />
        <Route path='pages'>
          <Route path='home' element={<PageWebsiteHome />} />
          <Route path='sign-in' element={<PageWebsiteSignIn />} />
          <Route
            path='courses-listing'
            element={<PageWebsiteCoursesListing />}
          />
          <Route
            path='courses-details'
            element={<PageWebsiteCourseDetails />}
          />
          <Route path='bags-listing' element={<PageWebsiteBagsListing />} />
          <Route path='bags-details' element={<PageWebsiteBagDetails />} />
          <Route
            path='companies-listing'
            element={<PageWebsiteCompaniesListing />}
          />
          <Route
            path='companies-details'
            element={<PageWebsiteCompanyDetails />}
          />
          <Route
            path='coordinators-listing'
            element={<PageWebsiteCoordinatorsListing />}
          />
          <Route
            path='coordinators-details'
            element={<PageWebsiteCoordinatorDetails />}
          />
          <Route
            path='marketers-listing'
            element={<PageWebsiteMarketersListing />}
          />
          <Route
            path='marketers-details'
            element={<PageWebsiteMarketerDetails />}
          />
          <Route
            path='trainers-listing'
            element={<PageWebsiteTrainersListing />}
          />
          <Route
            path='trainers-details'
            element={<PageWebsiteTrainerDetails />}
          />
          <Route path='search' element={<PageWebsiteSearch />} />
        </Route>
      </Route>
      <Route path={actuallPath}>
        <Route path='sign-in' element={<PageSignIn />} />
      </Route>
      <Route path='/*' element={<Page404 />} />
    </Routes>
  );
}
