import Constants from '../constants';
import { AxiosInstance } from 'axios';
import { getApiErrorMessage } from '../helpers/mappers';

let loadCoursesController: any;

export const fetchCourses =
  (axiosInstance: AxiosInstance) =>
  (
    page?: string,
    search?: string,
    orderby?: { [key: string]: 'ASC' | 'DESC' }[]
  ) => {
    try {
      if (loadCoursesController) loadCoursesController.abort();
      loadCoursesController = new AbortController();
      const response = axiosInstance.get('/dashboard_courses', {
        params: { page, search, orderby },
        signal: loadCoursesController.signal,
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const fetchCourse =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.get(`/dashboard_courses/${id}`);
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const createCourse =
  (axiosInstance: AxiosInstance) => async (course: any) => {
    try {
      const response = await axiosInstance.post(
        '/dashboard_courses',
        {
          title_en: course.title_en,
          title_ar: course.title_ar,
          badge_en: course.badge_en,
          badge_ar: course.badge_ar,
          brief_en: course.brief_en,
          brief_ar: course.brief_ar,
          start_date: course.start_date,
          registration_url: course.registration_url,
          duration_days: course.duration_days,
          duration_hours: course.duration_hours,
          price: course.price,
          keywords: course.keywords,
          contents: course.contents,
          categories: course.categories,
          subCategories: course.subCategories,
          trainer_name_en: course.trainer_name_en,
          trainer_name_ar: course.trainer_name_ar,
          organization_id: course.organization_id,
          profile_image: course.photo,
          content_image: course.contentPhoto,
        },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updateCourse =
  (axiosInstance: AxiosInstance) => async (course: any, id: string) => {
    try {
      let courseData: any = {
        title_en: course.title_en,
        title_ar: course.title_ar,
        badge_en: course.badge_en,
        badge_ar: course.badge_ar,
        brief_en: course.brief_en,
        brief_ar: course.brief_ar,
        start_date: course.start_date,
        registration_url: course.registration_url,
        duration_days: course.duration_days,
        duration_hours: course.duration_hours,
        price: course.price,
        keywords: course.keywords,
        contents: course.contents,
        categories: course.categories,
        subCategories: course.subCategories,
        trainer_name_en: course.trainer_name_en,
        trainer_name_ar: course.trainer_name_ar,
      };
      if (course.photo && typeof course.photo !== 'string') {
        courseData = {
          ...courseData,
          profile_image: course.photo,
        };
      }
      if (course.contentPhoto && typeof course.contentPhoto !== 'string') {
        courseData = {
          ...courseData,
          content_image: course.contentPhoto,
        };
      }
      const response = await axiosInstance.post(
        `/dashboard_courses/${id}`,
        courseData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updatePartialCourse =
  (axiosInstance: AxiosInstance) => async (course: any, id: string) => {
    try {
      let courseData: any = {
        title_en: course.title_en,
        title_ar: course.title_ar,
      };
      if (course.photo && typeof course.photo !== 'string') {
        courseData = {
          ...courseData,
          profile_image: course.photo,
        };
      }
      const response = await axiosInstance.post(
        `/dashboard_courses/${id}/partial`,
        courseData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const deleteCourse =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.delete(`/dashboard_courses/${id}`);
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };
