import Constants from '../constants';
import { AxiosInstance } from 'axios';
import { getApiErrorMessage } from '../helpers/mappers';

let loadOrganizationUpdateRequestsController: any;

export const fetchOrganizationUpdateRequests =
  (axiosInstance: AxiosInstance) =>
  (
    page?: string,
    search?: string,
    orderby?: { [key: string]: 'ASC' | 'DESC' }[]
  ) => {
    try {
      if (loadOrganizationUpdateRequestsController)
        loadOrganizationUpdateRequestsController.abort();
      loadOrganizationUpdateRequestsController = new AbortController();
      const response = axiosInstance.get('/organization_update_requests', {
        params: { page, search, orderby },
        signal: loadOrganizationUpdateRequestsController.signal,
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const fetchOrganizationUpdateRequest =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.get(
        `/organization_update_requests/${id}`
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updateOrganizationRequest =
  (axiosInstance: AxiosInstance) => async (updateRequest: any, id: string) => {
    try {
      const updateRequestData: any = {
        approved: updateRequest.approved,
      };
      const response = await axiosInstance.post(
        `/organization_update_requests/${id}`,
        updateRequestData
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const deleteOrganizationUpdateRequest =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.delete(
        `/organization_update_requests/${id}`
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };
