import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import styles from './index.module.scss';
import { Avatar, Card, Tabs } from 'antd';
import i18n from '../../i18n';
import TitleHeader from '../../components/titleHeader';
import Paragraph from '../../components/paragraph';
import FileUpload from '../../components/file-upload';
import ProfileAbout from '../../containers/profile/about';
import ProfileChangePassword from '../../containers/profile/changePassword';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { toast } from 'react-toastify';
import { fetchMyProfile, updatePartialAdmin } from '../../api/admins';
import { useAxios } from '../../hooks/useAxios';
import { partialText } from '../../helpers';
import { updateAuthUser } from '../../sagas/global/types';

interface PageIProfileProps {
  data?: any;
}

export default function PageProfile(props: PageIProfileProps) {
  const dispatch = useDispatch();
  const { axios } = useAxios();
  const authUser = useSelector(
    (state: AppState) => state.globalReducer.authUser
  );
  const [admin, setAdmin] = useState<any>(undefined);
  const [selectImage, setSelectImage] = useState<string | undefined>();
  const profileImgRef = useRef<any>(null);
  const handleClick = () => {
    profileImgRef.current?.click();
  };
  useEffect(() => {
    async function loadMyProfile() {
      const results: any = await fetchMyProfile(axios)();
      if (!('error' in results)) {
        setSelectImage(results.profile_image_url);
        setAdmin(results);
      }
    }
    loadMyProfile();
  }, []);
  const handleUpdateAvatar = async (values: any, id: string) => {
    const results: any = await updatePartialAdmin(axios)(values, id);
    if (results.error) {
      const toastMsg = i18n.t('global.entityUpdateFailed', {
        entity: i18n.t('global.entities.admin'),
      });
      toast.error(toastMsg, {
        position: i18n.language == 'ar' ? 'top-left' : 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      const updatedAuthUser = {
        ...(authUser as any),
        user: {
          ...(authUser as any).user,
          admin: {
            ...(authUser as any).user.admin,
            profile_image_url: results.profile_image_url,
          },
        },
      };
      typeof window !== 'undefined'
        ? window.localStorage.setItem('auth', JSON.stringify(updatedAuthUser))
        : false;
      dispatch(
        updateAuthUser({
          authUser: updatedAuthUser,
        })
      );
      const toastMsg = i18n.t('global.entityUpdatedSuccessfully', {
        entity: i18n.t('global.entities.admin'),
      });
      toast.success(toastMsg, {
        position: i18n.language == 'ar' ? 'top-left' : 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  return (
    <Container fluid={true} className={'p-0 m-0'}>
      <Row className={`m-0 ${styles['avatar-section']}`}>
        <Col
          xs={3}
          md={2}
          lg={1}
          className={'p-0 ms-3 d-flex align-items-center'}
        >
          <Avatar
            src={
              selectImage && selectImage.trim() != ''
                ? selectImage
                : 'https://via.placeholder.com/600'
            }
            size={70}
            className='pointer'
            alt='user-avatar'
            onClick={() => handleClick()}
          />
          <FileUpload
            ref={profileImgRef}
            accept='.jpg,.jpeg,.png,.webp,.gif'
            load={async (file, base64) => {
              setSelectImage(base64);
              if (admin)
                handleUpdateAvatar(
                  {
                    firstName: admin.first_name,
                    lastName: admin.last_name,
                    email: admin.user.email,
                    photo: file,
                  },
                  admin.id
                );
            }}
          />
        </Col>
        <Col
          xs={3}
          md={8}
          lg={9}
          className={'p-0 d-flex justify-content-start align-items-center'}
        >
          <h3 className='font-weight-bolder'>
            {admin ? partialText(`${admin.first_name} ${admin.last_name}`) : ''}
          </h3>
        </Col>
      </Row>
      <Row className={'m-0 px-md-5'}>
        <Col className={`${styles['about-card']}`}>
          <Card hoverable>
            <Tabs
              defaultActiveKey='1'
              tabBarExtraContent={
                <TitleHeader
                  type='subtitle'
                  text={i18n.t('pages.my-profile.about')}
                />
              }
              direction={i18n.language === 'ar' ? 'rtl' : 'ltr'}
              items={[
                {
                  label: i18n.t('pages.my-profile.activities'),
                  key: 'activity',
                  children: <ProfileAbout lastLogin={admin?.user.updated_at} />,
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
      <Row className={'m-0'}>
        <Col className={'text-center'}>
          <Card>
            <ProfileChangePassword />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
