const constants = {
  defaultLang: 'ar',
  itemsPerPage: 20,
  countryCodes: [
    {
      key: 'saudia',
      value: '966',
    },
    {
      key: 'egypt',
      value: '20',
    },
  ],
  serviceTypeCompanyIds: [1, 2, 3],
};

export default constants;
