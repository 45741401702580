import React from 'react';
import {
  PieChartOutlined,
  CopyOutlined,
  TeamOutlined,
  UserOutlined,
  ApartmentOutlined,
  BulbOutlined,
  DropboxOutlined,
  WalletOutlined,
  BlockOutlined,
  BuildOutlined,
  UpSquareFilled,
} from '@ant-design/icons';
import Permission from '../../enum/permission.enum';
import Role from '../../enum/role.enum';
import i18n from '../../i18n';
import TitleHeader from '../../components/titleHeader';

const getPagesItems = (
  canAccess: any,
  redirectReload: (path: string) => void,
  menuKeys: any
) => {
  const pagesItems: any[] = [];
  if (
    canAccess({
      permissions: [Permission.VIEW_HOME_PAGE],
    })
  ) {
    pagesItems.push({
      key: menuKeys.home.key,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.home')}
        />
      ),
      onClick: () => redirectReload('pages/home'),
    });
  }
  if (
    canAccess({
      permissions: [Permission.VIEW_SEARCH_PAGE],
    })
  ) {
    pagesItems.push({
      key: menuKeys.search.key,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.search')}
        />
      ),
      onClick: () => redirectReload('pages/search'),
    });
  }
  if (
    canAccess({
      permissions: [Permission.VIEW_SIGN_IN_PAGE],
    })
  ) {
    pagesItems.push({
      key: menuKeys.signIn.key,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.signIn')}
        />
      ),
      onClick: () => redirectReload('pages/sign-in'),
    });
  }
  if (
    canAccess({
      permissions: [Permission.VIEW_COURSES_PAGE],
    })
  ) {
    pagesItems.push({
      key: menuKeys.coursesListing.key,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.coursesListing')}
        />
      ),
      onClick: () => redirectReload('pages/courses-listing'),
    });
  }
  // if (
  //   canAccess({
  //     permissions: [Permission.VIEW_COURSE_PAGE],
  //   })
  // ) {
  //   pagesItems.push({
  //     key: menuKeys.coursesDetails.key,
  //     label: (
  //       <TitleHeader
  //         className='text-lightWhite mb-1'
  //         type='x-small'
  //         text={i18n.t('sideMenu.coursesDetails')}
  //       />
  //     ),
  //     onClick: () => redirectReload('pages/courses-details'),
  //   });
  // }
  if (
    canAccess({
      permissions: [Permission.VIEW_BAGS_PAGE],
    })
  ) {
    pagesItems.push({
      key: menuKeys.bagsListing.key,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.bagsListing')}
        />
      ),
      onClick: () => redirectReload('pages/bags-listing'),
    });
  }
  // if (
  //   canAccess({
  //     permissions: [Permission.VIEW_BAG_PAGE],
  //   })
  // ) {
  //   pagesItems.push({
  //     key: menuKeys.bagsDetails.key,
  //     label: (
  //       <TitleHeader
  //         className='text-lightWhite mb-1'
  //         type='x-small'
  //         text={i18n.t('sideMenu.bagsDetails')}
  //       />
  //     ),
  //     onClick: () => redirectReload('pages/bags-details'),
  //   });
  // }
  if (
    canAccess({
      permissions: [Permission.VIEW_COMPANIES_PAGE],
    })
  ) {
    pagesItems.push({
      key: menuKeys.companiesListing.key,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.companiesListing')}
        />
      ),
      onClick: () => redirectReload('pages/companies-listing'),
    });
  }
  // if (
  //   canAccess({
  //     permissions: [Permission.VIEW_COMPANY_PAGE],
  //   })
  // ) {
  //   pagesItems.push({
  //     key: menuKeys.companiesDetails.key,
  //     label: (
  //       <TitleHeader
  //         className='text-lightWhite mb-1'
  //         type='x-small'
  //         text={i18n.t('sideMenu.companiesDetails')}
  //       />
  //     ),
  //     onClick: () => redirectReload('pages/companies-details'),
  //   });
  // }
  if (
    canAccess({
      permissions: [Permission.VIEW_COORDINATORS_PAGE],
    })
  ) {
    pagesItems.push({
      key: menuKeys.coordinatorsListing.key,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.coordinatorsListing')}
        />
      ),
      onClick: () => redirectReload('pages/coordinators-listing'),
    });
  }
  // if (
  //   canAccess({
  //     permissions: [Permission.VIEW_COORDINATOR_PAGE],
  //   })
  // ) {
  //   pagesItems.push({
  //     key: menuKeys.coordinatorsDetails.key,
  //     label: (
  //       <TitleHeader
  //         className='text-lightWhite mb-1'
  //         type='x-small'
  //         text={i18n.t('sideMenu.coordinatorsDetails')}
  //       />
  //     ),
  //     onClick: () => redirectReload('pages/coordinators-details'),
  //   });
  // }
  if (
    canAccess({
      permissions: [Permission.VIEW_MARKETERS_PAGE],
    })
  ) {
    pagesItems.push({
      key: menuKeys.marketersListing.key,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.marketersListing')}
        />
      ),
      onClick: () => redirectReload('pages/marketers-listing'),
    });
  }
  // if (
  //   canAccess({
  //     permissions: [Permission.VIEW_MARKETER_PAGE],
  //   })
  // ) {
  //   pagesItems.push({
  //     key: menuKeys.marketersDetails.key,
  //     label: (
  //       <TitleHeader
  //         className='text-lightWhite mb-1'
  //         type='x-small'
  //         text={i18n.t('sideMenu.marketersDetails')}
  //       />
  //     ),
  //     onClick: () => redirectReload('pages/marketers-details'),
  //   });
  // }
  if (
    canAccess({
      permissions: [Permission.VIEW_TRAINERS_PAGE],
    })
  ) {
    pagesItems.push({
      key: menuKeys.trainersListing.key,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.trainersListing')}
        />
      ),
      onClick: () => redirectReload('pages/trainers-listing'),
    });
  }
  // if (
  //   canAccess({
  //     permissions: [Permission.VIEW_TRAINER_PAGE],
  //   })
  // ) {
  //   pagesItems.push({
  //     key: menuKeys.trainersDetails.key,
  //     label: (
  //       <TitleHeader
  //         className='text-lightWhite mb-1'
  //         type='x-small'
  //         text={i18n.t('sideMenu.trainersDetails')}
  //       />
  //     ),
  //     onClick: () => redirectReload('pages/trainers-details'),
  //   });
  // }
  return pagesItems;
};

export const getMenuItems = (
  canAccess: any,
  redirectReload: (path: string) => void,
  menuKeys: any,
  breakPoint: any
) => {
  const pagesItems = getPagesItems(canAccess, redirectReload, menuKeys);
  const menuItems: any[] = [
    {
      key: menuKeys.dashboard,
      icon: <PieChartOutlined />,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.dashboard')}
        />
      ),
      onClick: () => redirectReload('dashboard'),
    },
  ];
  if (
    canAccess({
      roles: [Role.SuperAdmin],
      permissions: [Permission.VIEW_ADMIN],
    })
  ) {
    menuItems.push({
      key: menuKeys.admins,
      icon: <TeamOutlined />,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.admins')}
        />
      ),
      onClick: () => redirectReload('admins'),
    });
  }
  if (
    canAccess({
      permissions: [Permission.VIEW_STUDENT],
    })
  ) {
    menuItems.push({
      key: menuKeys.students,
      icon: <UserOutlined />,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.students')}
        />
      ),
      onClick: () => redirectReload('students'),
    });
  }
  if (
    canAccess({
      permissions: [Permission.VIEW_ORGANIZATION],
    })
  ) {
    menuItems.push({
      key: menuKeys.organizations,
      icon: <ApartmentOutlined />,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.organizations')}
        />
      ),
      onClick: () => redirectReload('organizations'),
    });
  }
  if (
    canAccess({
      permissions: [Permission.VIEW_UPDATE_REQUEST_VIEW],
    })
  ) {
    menuItems.push({
      key: menuKeys.updateRequests,
      icon: <UpSquareFilled />,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.updateRequests')}
        />
      ),
      onClick: () => redirectReload('organization_update_requests'),
    });
  }
  if (
    canAccess({
      permissions: [Permission.VIEW_CATEGORIES],
    })
  ) {
    menuItems.push({
      key: menuKeys.categories,
      icon: <BlockOutlined />,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.categories')}
        />
      ),
      onClick: () => redirectReload('categories'),
    });
  }
  if (
    canAccess({
      permissions: [Permission.VIEW_SUB_CATEGORIES],
    })
  ) {
    menuItems.push({
      key: menuKeys.subCategories,
      icon: <BuildOutlined />,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.subCategories')}
        />
      ),
      onClick: () => redirectReload('sub_categories'),
    });
  }
  if (
    canAccess({
      permissions: [Permission.VIEW_COURSES],
    })
  ) {
    menuItems.push({
      key: menuKeys.courses,
      icon: <BulbOutlined />,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.courses')}
        />
      ),
      onClick: () => redirectReload('courses'),
    });
  }
  if (
    canAccess({
      permissions: [Permission.VIEW_BAGS],
    })
  ) {
    menuItems.push({
      key: menuKeys.bags,
      icon: <DropboxOutlined />,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.bags')}
        />
      ),
      onClick: () => redirectReload('bags'),
    });
  }
  if (
    canAccess({
      permissions: [Permission.VIEW_CONSULTATION_REQUEST_VIEW],
    })
  ) {
    menuItems.push({
      key: menuKeys.consultationRequests,
      icon: <WalletOutlined />,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.consultationRequests')}
        />
      ),
      onClick: () => redirectReload('consultation_requests'),
    });
  }
  if (pagesItems.length > 0) {
    menuItems.push({
      key: 'pages',
      icon: <CopyOutlined />,
      label: (
        <TitleHeader
          className='text-lightWhite mb-1'
          type='x-small'
          text={i18n.t('sideMenu.pages')}
        />
      ),
      popupClassName: breakPoint === 'xs' ? 'd-none' : '',
      children: pagesItems,
    });
  }
  return menuItems;
};
