import * as Yup from 'yup';

export const countryCode = (requiredMsg: string) =>
  Yup.string().required(requiredMsg);

export const phoneNumber = (requiredMsg: string, phoneMsg: string) =>
  Yup.string()
    .matches(/^[0]?(\d{1})?(\d{2})[- ]?(\d{3})[- ]?(\d{4})$/, phoneMsg)
    .required(requiredMsg);

export const email = (emailMsg: string, requiredMsg: string) =>
  Yup.string().email(emailMsg).required(requiredMsg);

export const password = (requiredMsg: string, minMsg: string, maxMsg: string) =>
  Yup.string().min(6, minMsg).max(100, maxMsg).required(requiredMsg);

export const passwordConfirmation = (matchMsg: string, requiredMsg: string) =>
  Yup.string()
    .oneOf([Yup.ref('password')], matchMsg)
    .required(requiredMsg);

export const name = (requiredMsg: string, minMsg: string, maxMsg: string) =>
  Yup.string().required(requiredMsg).min(3, minMsg).max(100, maxMsg);

export const longName = (requiredMsg: string, minMsg: string, maxMsg: string) =>
  Yup.string().required(requiredMsg).min(3, minMsg).max(100, maxMsg);

export const requiredInput = (requiredMsg: string) =>
  Yup.mixed().test('category_id', requiredMsg, (val: any) => {
    let isValid = true;
    if (val.value == 0) isValid = false;
    return isValid;
  });

export const requiredNumeric = (requiredMsg: string, numericMsg: string) =>
  Yup.number().typeError(numericMsg).integer(numericMsg).required(requiredMsg);

export const paragraph = (
  requiredMsg: string,
  minMsg: string,
  maxMsg: string
) => Yup.string().required(requiredMsg).min(100, minMsg).max(500, maxMsg);

export const badge = (requiredMsg: string, minMsg: string, maxMsg: string) =>
  Yup.string().required(requiredMsg).min(3, minMsg).max(100, maxMsg);

export const price = (numericMsg: string) =>
  Yup.string().matches(/[0-9]$|^$/g, numericMsg);

export const numeric = (numericMsg: string) =>
  Yup.string().matches(/[0-9]$|^$/g, numericMsg);
