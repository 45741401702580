import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import styles from './index.module.scss';
import LineChart from '../../components/charts/line';
import { fetchDashboardCharts } from '../../api/dashboard';
import { useAxios } from '../../hooks/useAxios';
import { formateDate } from '../../helpers';
import i18n from '../../i18n';

interface PageIDashboardProps {
  data?: any;
}

export default function PageDashboard(props: PageIDashboardProps) {
  const { axios } = useAxios();
  const [studentsCharts, setStudentsCharts] = useState<any>({
    xAxis: [],
    yAxis: [],
  });
  const [organizationsCharts, setOrganizationsCharts] = useState<any>({
    xAxis: [],
    yAxis: [],
  });
  useEffect(() => {
    async function callLoadCharts() {
      const loadLoadChartsApi = fetchDashboardCharts(axios);
      const response: any = await loadLoadChartsApi();
      if (response.students) {
        const studentsXaxis: any = [];
        const studentsYaxis: any = [];
        const organizationsXaxis: any = [];
        const organizationsYaxis: any = [];
        for (const student of response.students) {
          studentsXaxis.push(
            formateDate(
              student.creation_date,
              i18n.language as any,
              'MM/YYYY',
              'MMM'
            )
          );
          studentsYaxis.push(student.count);
        }
        for (const organization of response.organizations) {
          organizationsXaxis.push(
            formateDate(
              organization.creation_date,
              i18n.language as any,
              'MM/YYYY',
              'MMM'
            )
          );
          organizationsYaxis.push(organization.count);
        }
        setStudentsCharts({ xAxis: studentsXaxis, yAxis: studentsYaxis });
        setOrganizationsCharts({
          xAxis: organizationsXaxis,
          yAxis: organizationsYaxis,
        });
      }
    }
    callLoadCharts();
  }, []);
  return (
    <Container fluid={true} className={'p-2'}>
      <Row className={'m-0'}>
        <Col>
          <Row className={'m-0'}>
            <Col>
              <h3 className='font-weight-bolder arabic-font'>Statistics</h3>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={'m-0'}>
        <Col sm={6} className={'text-center'}>
          <LineChart
            title='Students'
            xAxislabels={studentsCharts.xAxis}
            yAxisLabels={studentsCharts.yAxis}
          />
        </Col>
        <Col sm={6} className={'text-center'}>
          <LineChart
            title='Organizations'
            xAxislabels={organizationsCharts.xAxis}
            yAxisLabels={organizationsCharts.yAxis}
          />
        </Col>
      </Row>
    </Container>
  );
}
