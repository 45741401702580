import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import styles from './index.module.scss';
import AdminsContainer from '../../containers/admins';
import Button from '../../components/button';
import { useRedirect } from '../../hooks/useRedirect';
import i18n from '../../i18n';
import Can from '../../containers/can';
import Role from '../../enum/role.enum';
import Permission from '../../enum/permission.enum';
import { useCanAccess } from '../../hooks/canAccess';
import TitleHeader from '../../components/titleHeader';

interface PageIAdminsProps {
  data?: any;
}

export default function PageAdmins(props: PageIAdminsProps) {
  const { canAccess } = useCanAccess();
  const redirect = useRedirect();
  if (
    !canAccess({
      roles: [Role.SuperAdmin],
      permissions: [Permission.VIEW_ADMIN],
    })
  ) {
    return (
      <Container fluid={true} className={'p-2'}>
        <Row className={'m-0'}>
          <Col sm={12} md={12} className={'d-flex justify-content-center'}>
            <TitleHeader type='title' text='Page not found' />
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Container fluid={true} className={'p-2'}>
      <Row className={'m-0'}>
        <Col sm={6} md={6}>
          <TitleHeader
            type='subtitle'
            text={i18n.t('pages.admin.listing-title')}
          />
        </Col>
        <Col sm={6} md={6} className='d-flex justify-content-end'>
          <Can
            roles={[Role.SuperAdmin]}
            permissions={[Permission.CREATE_ADMIN]}
          >
            <h3 className='font-weight-bolder'>
              <Button
                text={i18n.t('global.add-new-btn')}
                styleType='light'
                className='px-4'
                onClick={() => redirect('admins/add')}
              />
            </h3>
          </Can>
        </Col>
      </Row>
      <Row className={'m-0'}>
        <Col className={'text-center'}>
          <AdminsContainer />
        </Col>
      </Row>
    </Container>
  );
}
