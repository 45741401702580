import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { AppState } from '../../store';
import Loader from '../../components/loader';
import DataTable, { IHeaderColumn, IRow } from '../../components/dataTable';
import { useAxios } from '../../hooks/useAxios';
import { deleteStudent, fetchStudents } from '../../api/students';
import { useSearchParams } from 'react-router-dom';
import { Pagination } from 'listing-pagination';
import Constants from '../../constants';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import BtnStyles from '../../components/button/index.module.scss';
import i18n from '../../i18n';
import { IsJsonString, highlight } from '../../helpers';
import { headerColumnsBuilder, rowsBuilder } from './builders';
import Paragraph from '../../components/paragraph';
import { AiOutlineSearch } from 'react-icons/ai';
import Input from '../../components/input';
import { useRedirect } from '../../hooks/useRedirect';
import { useCanAccess } from '../../hooks/canAccess';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';

interface IStudentTableProps {
  data?: any;
}

function StudentsContainer(props: IStudentTableProps) {
  const dispatch = useDispatch();
  const { canAccess } = useCanAccess();
  const [showDeleteAlert, setShowDeleteAlert] = useState<{
    show: boolean;
    id: string;
  }>({ show: false, id: '0' });
  const redirectReload = useRedirect(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { axios } = useAxios();
  const [headerColumns, setHeaderColumns] = useState<IHeaderColumn[]>([]);
  const [loadedStudents, setLoadedStudents] = useState<any[]>([]);
  const [studentsTotalCount, setStudentsTotalCount] = useState(0);
  const [displayedRows, setDisplayedRows] = useState<IRow[]>([]);
  const [currentPage, setCurrentPage] = useState(searchParams.get('page') ?? 1);
  const [search, setSearch] = useState<string>(
    searchParams.get('search') ?? ''
  );
  useEffect(() => {
    // form stort columns
    const orderby: any = searchParams.get('orderby');
    const search: any = searchParams.get('search') ?? '';
    const page: any = searchParams.get('page') ?? 1;
    setCurrentPage(page);
    setSearch(search);
    const sortedColumns: any[] = IsJsonString(orderby)
      ? JSON.parse(orderby)
      : [];
    // api call
    async function loadStudents() {
      const mappedOrderBy = sortedColumns.map((obj: any) => ({
        [obj.columnName]: obj.sort,
      }));
      const results: any = await fetchStudents(axios)(
        page,
        search,
        mappedOrderBy
      );
      if (!('error' in results)) {
        setLoadedStudents(results.students);
        setStudentsTotalCount(results.totalCount);
      } else {
        setLoadedStudents([]);
      }
    }
    loadStudents();
    setHeaderColumns(
      headerColumnsBuilder(sortedColumns, searchParams, setSearchParams)
    );
  }, [searchParams]);

  useEffect(() => {
    setDisplayedRows(
      rowsBuilder(
        loadedStudents,
        loadedStudents,
        setLoadedStudents,
        redirectReload,
        setShowDeleteAlert,
        canAccess,
        axios
      )
    );
    if (loadedStudents.length > 0) {
      setTimeout(() => {
        highlight(
          search,
          document.querySelectorAll(
            'table tbody tr td:nth-child(2) span,table tbody tr td:nth-child(3) span'
          ),
          true
        );
      }, 100);
    }
  }, [loadedStudents]);

  return (
    <>
      <Row className={'m-0 p-0'}>
        <Col sm={3} className={'p-0 mb-3'}>
          <Input
            value={search ?? ''}
            type='text'
            placeholder={i18n.t('global.search').toString()}
            onChange={(e) => {
              searchParams.set('page', '1');
              searchParams.set('search', e.currentTarget.value);
              setSearchParams(searchParams);
            }}
            icon={<AiOutlineSearch />}
          />
        </Col>
      </Row>
      <Loader
        isLoading={false}
        errorMessage={
          displayedRows.length > 0 ? undefined : (
            <Paragraph>{i18n.t('pages.student.no-listing-data')}</Paragraph>
          )
        }
      >
        <Row className={'m-0 p-0'}>
          <Col sm={12} className={'p-0 text-center'}>
            <DataTable headerColumns={headerColumns} rows={displayedRows} />
          </Col>
          <Col sm={12} className={'mt-3 text-center'}>
            <Pagination
              totalItems={studentsTotalCount}
              currentPage={
                typeof currentPage === 'string'
                  ? parseInt(currentPage)
                  : currentPage
              }
              itemsPerPage={Constants.itemsPerPage}
              displayedNumbersCount={6}
              onChangePage={(pageNumber: number) => {
                searchParams.set('page', pageNumber.toString());
                setSearchParams(searchParams);
              }}
              OnPreBtnClick={(pageNumber: number) => {
                searchParams.set('page', pageNumber.toString());
                setSearchParams(searchParams);
              }}
              OnNextBtnClick={(pageNumber: number) => {
                searchParams.set('page', pageNumber.toString());
                setSearchParams(searchParams);
              }}
              hasNumbersGap
              hasNextPrevious
              previousBtnContent={
                <div className='d-flex font-gull-grey mt-0'>
                  <BsArrowLeft
                    size={20}
                    className={'arrow-pagination arrow-icon'}
                  />
                  <div>{i18n.t('global.pagination.previous')}</div>
                </div>
              }
              nextBtnContent={
                <div className='d-flex font-gull-grey mt-0'>
                  <div>{i18n.t('global.pagination.next')}</div>
                  <BsArrowRight
                    size={20}
                    className={'arrow-pagination arrow-icon'}
                  />
                </div>
              }
              styles={{
                position: 'center',
                numberBtnClass: `px-3 ${BtnStyles['button-light']} mx-1`,
                activeBtnClass: `${'active-button'} mx-1`,
              }}
            />
          </Col>
        </Row>
        <SweetAlert
          show={showDeleteAlert.show}
          danger
          showCancel
          confirmBtnText={i18n.t('global.yes')}
          confirmBtnBsStyle='danger'
          cancelBtnText={i18n.t('global.no')}
          cancelBtnBsStyle='primary'
          title={i18n.t('global.deletePopUpTitle')}
          onConfirm={() => {
            handleDelete(axios, showDeleteAlert.id);
            setShowDeleteAlert({ show: false, id: '0' });
          }}
          onCancel={() => {
            setShowDeleteAlert({ show: false, id: '0' });
          }}
          focusCancelBtn
        />
      </Loader>
    </>
  );
}

const handleDelete = async (axiosInstance: any, id: string) => {
  const results: any = await deleteStudent(axiosInstance)(id);
  if (results.error) {
    const toastMsg = i18n.t('global.entityDeleteFailed', {
      entity: i18n.t('global.entities.student'),
    });
    toast.error(toastMsg, {
      position: i18n.language == 'ar' ? 'top-left' : 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  } else {
    const toastMsg = i18n.t('global.entityDeletedSuccessfully', {
      entity: i18n.t('global.entities.student'),
    });
    toast.success(toastMsg, {
      position: i18n.language == 'ar' ? 'top-left' : 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
    setTimeout(() => location.reload(), 500);
  }
};

export default StudentsContainer;
