import { IsJsonString } from './index';

export const handleSortTableColumn = (
  searchParams: URLSearchParams,
  Colname: string
) => {
  const orderby: any = searchParams.get('orderby');
  let sortedColumns: any[] = IsJsonString(orderby) ? JSON.parse(orderby) : [];
  const currentColumn = sortedColumns.find((obj) => obj.columnName === Colname);
  if (!currentColumn) {
    searchParams.set(
      'orderby',
      JSON.stringify([...sortedColumns, { columnName: Colname, sort: 'ASC' }])
    );
  } else if (currentColumn.sort === 'ASC') {
    sortedColumns = sortedColumns.map((obj) =>
      obj.columnName == Colname ? { columnName: Colname, sort: 'DESC' } : obj
    );
    searchParams.set('orderby', JSON.stringify(sortedColumns));
  } else {
    sortedColumns = sortedColumns.filter((obj) => obj.columnName !== Colname);
    searchParams.set('orderby', JSON.stringify(sortedColumns));
  }
  return searchParams;
};

export const validateAccess = (
  userAccess: any[],
  validAccess: string[],
  operator: 'AND' | 'OR'
) => {
  let hasValidAccess = false;
  const hasAccessArr: boolean[] = [];
  validAccess.forEach((vAccess) => {
    let hasAccess = false;
    userAccess.forEach((uAccess) => {
      if (uAccess.name_en === vAccess) {
        hasAccess = true;
      }
    });
    hasAccessArr.push(hasAccess);
  });
  if (operator === 'AND') {
    hasValidAccess = hasAccessArr.reduce(function (accumulator, currentValue) {
      return accumulator && currentValue;
    }, true);
  } else {
    hasValidAccess = hasAccessArr.reduce(function (accumulator, currentValue) {
      return accumulator || currentValue;
    }, false);
  }
  return hasValidAccess;
};
