import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from 'formik';
import { yupErrorMapping } from '../../helpers';
import TitleHeader from '../../components/titleHeader';
import Input from '../../components/input';
import Button from '../../components/button';
import { useAxios } from '../../hooks/useAxios';
import { categorySchema } from './CategoryFormSchema';
import i18n from '../../i18n';
import Alert from '../../components/alert';
import Constants from '../../constants';
import { useGetLocalProp } from '../../hooks/getLocalProp';

interface ICategoryFormProps {
  category?: {
    name_en: string;
    name_ar: string;
  };
  submitForm: (formValues: any) => void;
  errors: string[];
}

const CategoryForm = ({ category, submitForm, errors }: ICategoryFormProps) => {
  const currentLang = i18n.language;
  const { axios } = useAxios();
  const { getLocalProp } = useGetLocalProp();
  const formik = useFormik({
    initialValues: {
      name_en: '',
      name_ar: '',
    },
    validate: async (values) => {
      return await yupErrorMapping(categorySchema(), values);
    },
    onSubmit: async (values) => {
      submitForm(values);
    },
  });

  useEffect(() => {
    if (category) {
      // other feilds
      formik.setFieldValue('name_en', category.name_en);
      formik.setFieldValue('name_ar', category.name_ar);
      // setTouched
      setTimeout(() => {
        formik.setFieldTouched('name_en', true);
        formik.setFieldTouched('name_ar', true);
      }, 300);
    }
  }, [category]);

  return (
    <Container fluid className='p-0 pt-0'>
      <Row className={'me-0 ms-0'}>
        <Col className='p-0'>
          <Row className={'me-0 ms-0'}>
            {errors.length > 0 && (
              <Col xs={12} className={'mt-3'}>
                <Alert
                  type='error'
                  multiple
                  content={
                    <div className='d-flex flex-column'>
                      {errors.map((error, i) => (
                        <p key={i} className='mb-0'>
                          {error}
                        </p>
                      ))}
                    </div>
                  }
                />
              </Col>
            )}
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col
              md={6}
              className={`mt-4 text-align-start ${
                currentLang === 'ar' ? 'order-2' : ''
              }`}
            >
              <Input
                className='text-dir-ltr'
                type='text'
                labelText={`${i18n.t('global.name_en')}`}
                placeholder={`${i18n.t('global.name_en')}`}
                textValue={formik.values.name_en}
                onBlur={(e: any) => {
                  formik.setFieldTouched('name_en', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('name_en', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.name_en
                    ? formik.errors.name_en
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.name_en && formik.errors.name_en
                    ? formik.errors.name_en
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                className='text-dir-rtl'
                type='text'
                labelText={`${i18n.t('global.name_ar')}`}
                placeholder={`${i18n.t('global.name_ar')}`}
                textValue={formik.values.name_ar}
                onBlur={(e: any) => {
                  formik.setFieldTouched('name_ar', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('name_ar', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.name_ar
                    ? formik.errors.name_ar
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.name_ar && formik.errors.name_ar
                    ? formik.errors.name_ar
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-5 d-flex'>
              <Button
                styleType='light'
                text={i18n.t('global.forms.submit')}
                disabled={
                  Object.keys(formik.errors).length > 0 ||
                  Object.keys(formik.touched).length === 0
                }
                onClick={async () => {
                  formik.submitForm();
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

CategoryForm.displayName = 'CategoryForm';
export default CategoryForm;
