enum websitePages {
  HomePage = 'HOME_PAGE',
  LoginPage = 'LOGIN_PAGE',
  CoursesPage = 'COURSES_PAGE',
  CourseDetailsPage = 'COURSE_DETAILS_PAGE',
  BagsPage = 'BAGS_PAGE',
  BagDetailsPage = 'BAG_DETAILS_PAGE',
  CompaniesPage = 'COMPANIES_PAGE',
  CompanyDetailsPage = 'COMPANY_DETAILS_PAGE',
  CoordinatorsPage = 'COORDINATORS_PAGE',
  CoordinatorDetailsPage = 'COORDINATOR_DETAILS_PAGE',
  MarketersPage = 'MARKETERS_PAGE',
  MarketerDetailsPage = 'MARKETER_DETAILS_PAGE',
  TrainersPage = 'TRAINERS_PAGE',
  TrainerDetailsPage = 'TRAINER_DETAILS_PAGE',
  SearchPage = 'SEARCH_PAGE',
}

export default websitePages;
