import styles from './index.module.scss';
import React from 'react';

export type TitleHeaderProps = {
  className?: string;
  text: string;
  hoverTitle?: string;
  type: 'title' | 'subtitle' | 'smaller' | 'x-small';
  hasUnderline?: boolean;
  underLineclass?: string;
  align?: 'start' | 'center' | 'end';
};

const TitleHeader = React.forwardRef(
  (
    {
      className,
      text,
      hoverTitle,
      type,
      hasUnderline,
      underLineclass,
      align,
      ...rest
    }: TitleHeaderProps & React.HTMLAttributes<HTMLHeadingElement>,
    ref: React.Ref<HTMLHeadingElement>
  ) => {
    const typeMap = {
      subtitle: styles['subtitleHeader'],
      smaller: styles['smallertitleHeader'],
      'x-small': styles['x-smallertitleHeader'],
    };
    return (
      <div
        title={hoverTitle ?? undefined}
        className={`d-flex flex-column align-items-${align ?? 'start'}`}
      >
        {type === 'title' ? (
          <h3
            ref={ref}
            className={`d-flex flex-column ${styles['titleHeader']} ${
              className ?? ''
            }`}
            {...rest}
          >
            <div dangerouslySetInnerHTML={{ __html: text }} />
            {hasUnderline && (
              <div className={`${underLineclass} ${styles['underline']}`}></div>
            )}
          </h3>
        ) : (
          <h5
            ref={ref}
            className={`d-flex flex-column ${typeMap[type]} ${className ?? ''}`}
            {...rest}
          >
            <div dangerouslySetInnerHTML={{ __html: text }} />
            {hasUnderline && (
              <div className={`${underLineclass} ${styles['underline']}`}></div>
            )}
          </h5>
        )}
      </div>
    );
  }
);

TitleHeader.displayName = 'TitleHeader';
export default TitleHeader;
