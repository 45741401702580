import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';

export type LineChartProps = {
  className?: string;
  title: string;
  xAxislabels: string[];
  yAxisLabels: any[];
};

const LineChart = React.forwardRef(
  (
    { className, title, xAxislabels, yAxisLabels }: LineChartProps,
    ref: any
  ) => {
    const canvasRef = useRef<any>(null);
    const [chart, setChart] = useState<any>();
    useEffect(() => {
      if (chart) {
        chart.destroy();
      }
      const createdChart = new Chart(canvasRef.current, {
        type: 'line',
        data: {
          labels: xAxislabels,
          datasets: [
            {
              label: title,
              data: yAxisLabels,
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1,
            },
          ],
        },
      });
      setChart(createdChart);
    }, [xAxislabels, yAxisLabels]);
    return (
      <div>
        <canvas ref={canvasRef}></canvas>
      </div>
    );
  }
);

LineChart.displayName = 'LineChart';
export default LineChart;
