import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { Avatar, Layout, Menu } from 'antd';
import TitleHeader from '../../components/titleHeader';
import i18n from '../../i18n';
import { useLocation, useParams } from 'react-router-dom';
import { useRedirect } from '../../hooks/useRedirect';
import _ from 'lodash';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import { logout } from '../../api/authentication';
import { useAxios } from '../../hooks/useAxios';
import { partialText } from '../../helpers';
import { useCanAccess } from '../../hooks/canAccess';
import { getMenuItems } from './validateUserAccess';

const { Sider } = Layout;
interface ISideMenuProps {
  sideMenuCollapse?: boolean;
  setSideMenuCollapse?: (collapse: boolean) => void;
}

function SideMenu({ sideMenuCollapse, setSideMenuCollapse }: ISideMenuProps) {
  const { canAccess } = useCanAccess();
  const dispatch = useDispatch();
  const authUser = useSelector(
    (state: AppState) => state.globalReducer.authUser
  );
  const location = useLocation();
  const redirectReload = useRedirect(true);
  const { axios } = useAxios();
  const breakPoint = useBreakPoint();
  const menuKeys = {
    dashboard: 'dashboard',
    admins: 'admins',
    students: 'students',
    organizations: 'organizations',
    subCategories: 'sub_categories',
    categories: 'categories',
    courses: 'courses',
    bags: 'bags',
    consultationRequests: 'consultation_requests',
    updateRequests: 'organization_update_requests',
    home: { key: 'home', subMenuKey: 'pages' },
    signIn: { key: 'sign-in', subMenuKey: 'pages' },
    coursesListing: { key: 'courses-listing', subMenuKey: 'pages' },
    coursesDetails: { key: 'courses-details', subMenuKey: 'pages' },
    bagsListing: { key: 'bags-listing', subMenuKey: 'pages' },
    bagsDetails: { key: 'bags-details', subMenuKey: 'pages' },
    companiesListing: { key: 'companies-listing', subMenuKey: 'pages' },
    companiesDetails: { key: 'companies-details', subMenuKey: 'pages' },
    coordinatorsListing: { key: 'coordinators-listing', subMenuKey: 'pages' },
    coordinatorsDetails: { key: 'coordinators-details', subMenuKey: 'pages' },
    marketersListing: { key: 'marketers-listing', subMenuKey: 'pages' },
    marketersDetails: { key: 'marketers-details', subMenuKey: 'pages' },
    trainersListing: { key: 'trainers-listing', subMenuKey: 'pages' },
    trainersDetails: { key: 'trainers-details', subMenuKey: 'pages' },
    search: { key: 'search', subMenuKey: 'pages' },
  };
  const mainUrlSegement = location.pathname.split('/')[1];
  const nestedMainUrlSegement = location.pathname.split('/')[2];
  const defaultSelected: string | { key: string; subMenuKey: string } =
    _.find(
      menuKeys,
      (val, key) =>
        (typeof val == 'string' &&
          (mainUrlSegement === val || nestedMainUrlSegement === val)) ||
        (typeof val == 'object' &&
          (mainUrlSegement === val.key || nestedMainUrlSegement === val.key))
    ) ?? '';
  const defaultSelectedKey =
    typeof defaultSelected == 'string' ? defaultSelected : defaultSelected.key;
  const defaultOpenedKey =
    typeof defaultSelected == 'object' ? defaultSelected.subMenuKey : '';
  return (
    <>
      <div
        className={`${styles['menu-mobile-blocker']} ${
          sideMenuCollapse ? styles['closed'] : styles['opened']
        }`}
        onClick={() => setSideMenuCollapse?.(true)}
      ></div>
      <Sider
        className={`${styles['fixed-menu']} ${
          sideMenuCollapse ? styles['closed'] : styles['opened']
        }`}
        collapsed={sideMenuCollapse}
      >
        <Menu
          theme='dark'
          mode='inline'
          className='mb-3'
          items={[
            {
              key: 'my-profile',
              className: `${styles['no-bg']} ${
                sideMenuCollapse ? 'd-flex justify-content-center' : ''
              }`,
              onClick: () => redirectReload('my-profile'),
              label: (
                <>
                  <Avatar
                    src={
                      authUser?.user.admin?.profile_image_url == ''
                        ? 'https://via.placeholder.com/150'
                        : authUser?.user.admin?.profile_image_url
                    }
                    className='pointer'
                    alt='user-avatar'
                  />
                  {!sideMenuCollapse && (
                    <span className='ms-2 d-inline-block'>
                      <TitleHeader
                        className='text-lightWhite mb-1'
                        type='x-small'
                        text={
                          authUser?.user.admin
                            ? partialText(
                                `${authUser?.user.admin.first_name} ${authUser?.user.admin.last_name}`
                              )
                            : ''
                        }
                      />
                    </span>
                  )}
                </>
              ),
            },
          ]}
        />
        <Menu
          theme='dark'
          mode='inline'
          className={styles['scroll-menu-list']}
          defaultSelectedKeys={[defaultSelectedKey]}
          defaultOpenKeys={[defaultOpenedKey]}
          items={getMenuItems(canAccess, redirectReload, menuKeys, breakPoint)}
        />
        <Menu
          theme='dark'
          mode='inline'
          className={`${styles['bottom-menu']}`}
          items={[
            // {
            //   key: 'settings',
            //   className: `${styles['no-bg']}`,
            //   icon: <SettingOutlined />,
            //   label: i18n.t('sideMenu.settings'),
            //   onClick: () => redirectReload('settings'),
            // },
            {
              key: 'logout',
              className: `${styles['no-bg']}`,
              icon: <LogoutOutlined />,
              label: (
                <TitleHeader
                  className='text-lightWhite mb-1'
                  type='x-small'
                  text={i18n.t('sideMenu.logout')}
                />
              ),
              onClick: async () => {
                const loginApi = logout(axios);
                await loginApi();
                localStorage.clear();
                redirectReload();
              },
            },
          ]}
        />
      </Sider>
    </>
  );
}

export default SideMenu;
