import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import styles from './index.module.scss';
import AddEditStudentContainer from '../../containers/students/addEdit';
import { useParams } from 'react-router-dom';
import i18n from '../../i18n';
import TitleHeader from '../../components/titleHeader';
import Permission from '../../enum/permission.enum';
import Role from '../../enum/role.enum';
import { useCanAccess } from '../../hooks/canAccess';

interface IAddEditStudentsPageProps {
  data?: any;
}

export default function PageAddEditStudent(props: IAddEditStudentsPageProps) {
  const { id } = useParams();
  const { canAccess } = useCanAccess();
  if (
    (id &&
      !canAccess({
        permissions: [Permission.EDIT_STUDENT],
      })) ||
    (!id &&
      !canAccess({
        permissions: [Permission.CREATE_STUDENT],
      }))
  ) {
    return (
      <Container fluid={true} className={'p-2'}>
        <Row className={'m-0'}>
          <Col sm={12} md={12} className={'d-flex justify-content-center'}>
            <TitleHeader type='title' text='Page not found' />
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Container fluid={true} className={'p-2'}>
      <Row className={'m-0'}>
        <Col sm={6} md={6} className='p-0'>
          <TitleHeader
            type='subtitle'
            text={
              id
                ? i18n.t('pages.student.form-edit-title')
                : i18n.t('pages.student.form-add-title')
            }
          />
        </Col>
      </Row>
      <Row className={'m-0 mt-3'}>
        <Col className='p-0'>
          <AddEditStudentContainer />
        </Col>
      </Row>
    </Container>
  );
}
