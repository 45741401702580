import * as Yup from 'yup';
import i18n from '../../i18n';
import {
  categories,
  contents,
  keywords,
  ownerId,
} from '../../validation/courses';
import {
  requiredInput,
  name,
  longName,
  paragraph,
  badge,
  requiredNumeric,
  price,
  numeric,
} from '../../validation';

export const courseSchema = (isEdit?: boolean) => {
  let schemaObj: any = {
    title_en: longName(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.longNameMax')
    ),
    title_ar: longName(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.longNameMax')
    ),
    trainer_name_en: longName(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.longNameMax')
    ),
    trainer_name_ar: longName(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.longNameMax')
    ),
    brief_en: paragraph(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.paragraphMin'),
      i18n.t('global.validation.paragraphMax')
    ),
    brief_ar: paragraph(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.paragraphMin'),
      i18n.t('global.validation.paragraphMax')
    ),
    badge_en: badge(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.badgehMin'),
      i18n.t('global.validation.badgehMax')
    ),
    badge_ar: badge(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.badgehMin'),
      i18n.t('global.validation.badgehMax')
    ),
    duration_days: numeric(i18n.t('global.validation.numeric')),
    duration_hours: numeric(i18n.t('global.validation.numeric')),
    price: price(i18n.t('global.validation.numeric')),
    categories: categories(i18n.t('global.validation.required')),
    subCategories: categories(i18n.t('global.validation.required')),
    keywords: keywords(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.nameMax')
    ),
    contents: contents(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.nameMax')
    ),
  };
  if (!isEdit) {
    schemaObj = {
      ...schemaObj,
      organization_id: ownerId(i18n.t('global.validation.required')),
    };
  }
  return Yup.object().shape(schemaObj);
};
