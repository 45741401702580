import Constants from '../constants';
import { AxiosInstance } from 'axios';
import { getApiErrorMessage } from '../helpers/mappers';

let loadConsultationRequestsController: any;

export const fetchConsultationRequests =
  (axiosInstance: AxiosInstance) =>
  (
    page?: string,
    search?: string,
    orderby?: { [key: string]: 'ASC' | 'DESC' }[]
  ) => {
    try {
      if (loadConsultationRequestsController)
        loadConsultationRequestsController.abort();
      loadConsultationRequestsController = new AbortController();
      const response = axiosInstance.get('/consultation_requests', {
        params: { page, search, orderby },
        signal: loadConsultationRequestsController.signal,
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const fetchConsultationRequest =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.get(`/consultation_requests/${id}`);
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const deleteConsultationRequest =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.delete(
        `/consultation_requests/${id}`
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };
