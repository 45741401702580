import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from 'formik';
import { yupErrorMapping } from '../../helpers';
import TitleHeader from '../../components/titleHeader';
import Input from '../../components/input';
import Button from '../../components/button';
import { useAxios } from '../../hooks/useAxios';
import { websiteSEOSchema } from './WebsiteSEOFormSchema';
import i18n from '../../i18n';
import Alert from '../../components/alert';
import Constants from '../../constants';

interface IWebsiteSEOProps {
  seo: any;
  submitForm: (formValues: any) => void;
  errors: string[];
}

const WebsiteSEOForm = ({ seo, submitForm, errors }: IWebsiteSEOProps) => {
  const currentLang = i18n.language;
  const { axios } = useAxios();
  const formik = useFormik({
    initialValues: {
      meta_title_en: '',
      meta_title_ar: '',
      meta_description_en: '',
      meta_description_ar: '',
      meta_keywords_en: '',
      meta_keywords_ar: '',
    },
    validate: async (values) => {
      return await yupErrorMapping(websiteSEOSchema(), values);
    },
    onSubmit: async (values) => {
      submitForm(values);
    },
  });
  useEffect(() => {
    if (seo) {
      // other feilds
      formik.setFieldValue('meta_title_en', seo.meta_title_en ?? '');
      formik.setFieldValue('meta_title_ar', seo.meta_title_ar ?? '');
      formik.setFieldValue(
        'meta_description_en',
        seo.meta_description_en ?? ''
      );
      formik.setFieldValue(
        'meta_description_ar',
        seo.meta_description_ar ?? ''
      );
      formik.setFieldValue('meta_keywords_en', seo.meta_keywords_en ?? '');
      formik.setFieldValue('meta_keywords_ar', seo.meta_keywords_ar ?? '');
      // setTouched
      setTimeout(() => {
        formik.setFieldTouched('meta_title_en', true);
        formik.setFieldTouched('meta_title_ar', true);
        formik.setFieldTouched('meta_description_en', true);
        formik.setFieldTouched('meta_description_ar', true);
        formik.setFieldTouched('meta_keywords_en', true);
        formik.setFieldTouched('meta_keywords_ar', true);
      }, 300);
    }
  }, [seo]);

  return (
    <Container fluid className='p-0 pt-0'>
      <Row className={'me-0 ms-0'}>
        <Col className='p-0'>
          <Row className={'me-0 ms-0'}>
            {errors.length > 0 && (
              <Col xs={12} className={'mt-3'}>
                <Alert
                  type='error'
                  multiple
                  content={
                    <div className='d-flex flex-column'>
                      {errors.map((error, i) => (
                        <p key={i} className='mb-0'>
                          {error}
                        </p>
                      ))}
                    </div>
                  }
                />
              </Col>
            )}
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-3 d-flex justify-content-center'>
              <TitleHeader type='title' text='SEO' />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col
              md={6}
              className={`mt-4 text-align-start ${
                currentLang === 'ar' ? 'order-2' : ''
              }`}
            >
              <Input
                className='text-dir-ltr'
                type='text'
                labelText={`${i18n.t('global.meta_title_en')}`}
                placeholder={`${i18n.t('global.meta_title_en')}`}
                textValue={formik.values.meta_title_en}
                onBlur={(e: any) => {
                  formik.setFieldTouched('meta_title_en', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('meta_title_en', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.meta_title_en
                    ? formik.errors.meta_title_en
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.meta_title_en && formik.errors.meta_title_en
                    ? formik.errors.meta_title_en
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                className='text-dir-rtl'
                type='text'
                labelText={`${i18n.t('global.meta_title_ar')}`}
                placeholder={`${i18n.t('global.meta_title_ar')}`}
                textValue={formik.values.meta_title_ar}
                onBlur={(e: any) => {
                  formik.setFieldTouched('meta_title_ar', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('meta_title_ar', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.meta_title_ar
                    ? formik.errors.meta_title_ar
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.meta_title_ar && formik.errors.meta_title_ar
                    ? formik.errors.meta_title_ar
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              className={`mt-4 text-align-start ${
                currentLang === 'ar' ? 'order-2' : ''
              }`}
            >
              <Input
                type='textArea'
                labelText={`${i18n.t('global.meta_description_en')}`}
                placeholder={`${i18n.t('global.meta_description_en')}`}
                textAreaValue={formik.values.meta_description_en}
                onBlur={(e: any) => {
                  formik.setFieldTouched('meta_description_en', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue(
                    'meta_description_en',
                    e.currentTarget.value
                  );
                }}
                validationCheck={
                  formik.touched.meta_description_en
                    ? formik.errors.meta_description_en
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.meta_description_en &&
                  formik.errors.meta_description_en
                    ? formik.errors.meta_description_en
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='textArea'
                labelText={`${i18n.t('global.meta_description_ar')}`}
                placeholder={`${i18n.t('global.meta_description_ar')}`}
                textAreaValue={formik.values.meta_description_ar}
                onBlur={(e: any) => {
                  formik.setFieldTouched('meta_description_ar', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue(
                    'meta_description_ar',
                    e.currentTarget.value
                  );
                }}
                validationCheck={
                  formik.touched.meta_description_ar
                    ? formik.errors.meta_description_ar
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.meta_description_ar &&
                  formik.errors.meta_description_ar
                    ? formik.errors.meta_description_ar
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col
              md={6}
              className={`mt-4 text-align-start ${
                currentLang === 'ar' ? 'order-2' : ''
              }`}
            >
              <Input
                type='text'
                labelText={`${i18n.t('global.meta_keywords_en')}`}
                placeholder={`${i18n.t('global.meta_keywords_placeholder_en')}`}
                textValue={formik.values.meta_keywords_en}
                onBlur={(e: any) => {
                  formik.setFieldTouched('meta_keywords_en', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue(
                    'meta_keywords_en',
                    e.currentTarget.value
                  );
                }}
                validationCheck={
                  formik.touched.meta_keywords_en
                    ? formik.errors.meta_keywords_en
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.meta_keywords_en &&
                  formik.errors.meta_keywords_en
                    ? formik.errors.meta_keywords_en
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t('global.meta_keywords_ar')}`}
                placeholder={`${i18n.t('global.meta_keywords_placeholder_ar')}`}
                textValue={formik.values.meta_keywords_ar}
                onBlur={(e: any) => {
                  formik.setFieldTouched('meta_keywords_ar', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue(
                    'meta_keywords_ar',
                    e.currentTarget.value
                  );
                }}
                validationCheck={
                  formik.touched.meta_keywords_ar
                    ? formik.errors.meta_keywords_ar
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.meta_keywords_ar &&
                  formik.errors.meta_keywords_ar
                    ? formik.errors.meta_keywords_ar
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-5 d-flex'>
              <Button
                styleType='light'
                text={i18n.t('global.forms.submit')}
                disabled={
                  Object.keys(formik.errors).length > 0 ||
                  Object.keys(formik.touched).length === 0
                }
                onClick={async () => {
                  formik.submitForm();
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default WebsiteSEOForm;
