import * as Yup from 'yup';

export const organizationName = (requiredMsg: string) =>
  Yup.string().required(requiredMsg);

export const licenseNumber = (requiredMsg: string) =>
  Yup.string().required(requiredMsg);

export const commercialRegister = (requiredMsg: string) =>
  Yup.string().required(requiredMsg);

export const communicationOfficer = (requiredMsg: string) =>
  Yup.string().required(requiredMsg);

export const coursesDepartments = (requiredMsg: string) =>
  Yup.array().min(1, requiredMsg);

export const serviceOwnerType = (requiredMsg: string) =>
  Yup.mixed().test('serviceType_id', requiredMsg, (val: any) => {
    let isValid = true;
    if (val.value == 0) isValid = false;
    return isValid;
  });

export const wage = (numericMsg: string) =>
  Yup.string().matches(/[0-9]$|^$/g, numericMsg);
