import * as Yup from 'yup';
import { longName, paragraph } from '../../validation';
import i18n from '../../i18n';

export const websiteSEOSchema = () =>
  Yup.object().shape({
    meta_title_en: longName(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.longNameMax')
    ),
    meta_title_ar: longName(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.nameMin'),
      i18n.t('global.validation.longNameMax')
    ),
    meta_description_en: paragraph(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.paragraphMin'),
      i18n.t('global.validation.paragraphMax')
    ),
    meta_description_ar: paragraph(
      i18n.t('global.validation.required'),
      i18n.t('global.validation.paragraphMin'),
      i18n.t('global.validation.paragraphMax')
    ),
  });
