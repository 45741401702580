import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { validateAccess } from '../helpers/logic';

export type CanAccessProps = {
  permissions: string[];
  roles?: string[];
  permissionsOperator?: 'AND' | 'OR';
  rolesOperator?: 'AND' | 'OR';
};

export const useCanAccess = () => {
  const authUser = useSelector(
    (state: AppState) => state.globalReducer.authUser
  );
  const canAccess = useCallback(
    ({
      permissions,
      roles,
      permissionsOperator = 'AND',
      rolesOperator = 'AND',
    }: CanAccessProps) => {
      if (authUser) {
        const userPermissions = authUser.user.permissions ?? [];
        const userRoles = authUser.user.roles ?? [];
        let hasRoleAccess = true;
        let hasPermissionAccess = false;
        if (roles) {
          hasRoleAccess = validateAccess(userRoles, roles, rolesOperator);
        }
        if (permissions.length)
          hasPermissionAccess = validateAccess(
            userPermissions,
            permissions,
            permissionsOperator
          );
        return hasRoleAccess && hasPermissionAccess;
      }
    },
    []
  );
  return { canAccess };
};
