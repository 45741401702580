import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Card } from 'antd';
import CourseForm from '../../forms/courses';
import { useParams } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios';
import { useRedirect } from '../../hooks/useRedirect';
import { createCourse, fetchCourse, updateCourse } from '../../api/courses';
import i18n from '../../i18n';
import { toast } from 'react-toastify';
import { updateScreenLoader } from '../../sagas/global/types';
import LoaderCounter from '../../components/loaderCounter';

interface IAddEditCourseContainerProps {
  data?: any;
}

function AddEditCourseContainer(props: IAddEditCourseContainerProps) {
  const onUploadProgress = (uploadedPercent: number) => {
    dispatch(
      updateScreenLoader({
        isOpen: true,
        content: <LoaderCounter content={uploadedPercent.toString()} />,
      })
    );
  };
  const dispatch = useDispatch();
  const { id } = useParams();
  const { axios } = useAxios({ onUploadProgress: onUploadProgress });
  const redirectReload = useRedirect(true);
  const [course, setCourse] = useState<any>(undefined);
  const [errors, setErrors] = useState<string[]>([]);
  useEffect(() => {
    async function loadCourse() {
      const results: any = await fetchCourse(axios)(id as string);
      if (!('error' in results)) {
        setCourse(results);
      }
    }
    if (id) loadCourse();
  }, []);
  // TODO redundant add / edit methods
  const handleAdd = async (values: any) => {
    const results: any = await createCourse(axios)(values);
    dispatch(
      updateScreenLoader({
        isOpen: false,
        content: '',
      })
    );
    if (results.error) {
      let errors: Array<string> = [];
      if (typeof results.message === 'string') {
        errors.push(results.message);
      } else {
        if (Array.isArray(results.message))
          Object.keys(results.message).forEach((key: any) => {
            errors = [...errors, results.message[key]];
          });
        else {
          Object.keys(results.message).forEach((key: any) => {
            errors = [...errors, ...results.message[key]];
          });
        }
      }
      setErrors(errors);
      const toastMsg = i18n.t('global.entityCreateFailed', {
        entity: i18n.t('global.entities.course'),
      });
      toast.error(toastMsg, {
        position: i18n.language == 'ar' ? 'top-left' : 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      const toastMsg = i18n.t('global.entityCreatedSuccessfully', {
        entity: i18n.t('global.entities.course'),
      });
      setErrors([]);
      toast.success(toastMsg, {
        position: i18n.language == 'ar' ? 'top-left' : 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setTimeout(() => redirectReload('courses'), 500);
    }
  };
  const handleEdit = async (values: any, id: string) => {
    const results: any = await updateCourse(axios)(values, id);
    dispatch(
      updateScreenLoader({
        isOpen: false,
        content: '',
      })
    );
    if (results.error) {
      let errors: Array<string> = [];
      if (typeof results.message === 'string') {
        errors.push(results.message);
      } else {
        if (Array.isArray(results.message))
          Object.keys(results.message).forEach((key: any) => {
            errors = [...errors, results.message[key]];
          });
        else {
          Object.keys(results.message).forEach((key: any) => {
            errors = [...errors, ...results.message[key]];
          });
        }
      }
      setErrors(errors);
      const toastMsg = i18n.t('global.entityUpdateFailed', {
        entity: i18n.t('global.entities.course'),
      });
      toast.error(toastMsg, {
        position: i18n.language == 'ar' ? 'top-left' : 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      const toastMsg = i18n.t('global.entityUpdatedSuccessfully', {
        entity: i18n.t('global.entities.course'),
      });
      setErrors([]);
      toast.success(toastMsg, {
        position: i18n.language == 'ar' ? 'top-left' : 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setTimeout(() => redirectReload('courses'), 500);
    }
  };

  return (
    <Row className={'m-0 p-0'}>
      <Col sm={12} className={'p-0 text-center'}>
        <Card>
          <CourseForm
            course={course}
            submitForm={(values) => {
              id ? handleEdit(values, id) : handleAdd(values);
            }}
            errors={errors}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default AddEditCourseContainer;
