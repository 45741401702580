import React, { useEffect, useState } from 'react';
import { IHeaderColumn, IRow } from '../../components/dataTable';
import { handleSortTableColumn } from '../../helpers/logic';
import Paragraph from '../../components/paragraph';
import Input from '../../components/input';
import { Dropdown } from 'antd';
import { AiOutlineMore } from 'react-icons/ai';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { updatePartialSubCategory } from '../../api/categories';
import Permission from '../../enum/permission.enum';

export const headerColumnsBuilder = (
  searchParamsOrderby: { columnName: string; sort: 'ASC' | 'DESC' }[],
  searchParams: any,
  setSearchParams: (val: any) => void
): IHeaderColumn[] => {
  let columns: IHeaderColumn[] = [
    {
      name: 'id',
      displayedName: 'Id',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'id'));
      },
    },
    {
      name: 'category_id',
      displayedName: 'Parent category',
    },
    {
      name: 'name_en',
      displayedName: 'Name (EN)',
    },
    {
      name: 'name_ar',
      displayedName: 'Name (AR)',
    },
    {
      name: 'created_at',
      displayedName: 'Created at',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'created_at'));
      },
    },
    {
      name: 'updated_at',
      displayedName: 'Updated at',
    },
    {
      name: 'actions',
      displayedName: 'Actions',
    },
  ];

  searchParamsOrderby.forEach((val, i) => {
    columns = columns.map((column) => {
      return {
        ...column,
        sorted: column.name === val.columnName ? val.sort : column.sorted,
      };
    });
  });
  return columns;
};

export const rowsBuilder = (
  subCategories: any[],
  loadedSubCategories: any[],
  setLoadedSubCategories: (val: any) => void,
  redirectReload: (route: string) => void,
  setShowDeleteAlert: (val: { show: boolean; id: string }) => void,
  canAccess: any,
  axiosInstance: any
): IRow[] => {
  return subCategories.map((val, i) => ({
    dataObj: val,
    props: [
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.id}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.category_id}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return (
            <Paragraph
              editable={
                <Input
                  type='text'
                  value={rowValue.name_en}
                  onChange={(e) => {
                    setLoadedSubCategories(
                      loadedSubCategories.map((subCategory, i) => {
                        return subCategory.id == rowValue.id
                          ? { ...subCategory, name_en: e.target.value }
                          : subCategory;
                      })
                    );
                  }}
                />
              }
              onLeaveEdit={() => {
                handleEdit(
                  axiosInstance,
                  {
                    name_en: rowValue.name_en,
                    name_ar: rowValue.name_ar,
                  },
                  rowValue.id
                );
              }}
            >
              {rowValue.name_en}
            </Paragraph>
          );
        },
      },
      {
        loadContent: (rowValue) => {
          return (
            <Paragraph
              editable={
                <Input
                  type='text'
                  value={rowValue.name_ar}
                  onChange={(e) => {
                    setLoadedSubCategories(
                      loadedSubCategories.map((subCategory, i) => {
                        return subCategory.id == rowValue.id
                          ? { ...subCategory, name_ar: e.target.value }
                          : subCategory;
                      })
                    );
                  }}
                />
              }
              onLeaveEdit={() => {
                handleEdit(
                  axiosInstance,
                  {
                    name_en: rowValue.name_en,
                    name_ar: rowValue.name_ar,
                  },
                  rowValue.id
                );
              }}
            >
              {rowValue.name_ar}
            </Paragraph>
          );
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.created_at}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.updated_at}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          const actionItems: any[] = [];
          if (
            canAccess({
              permissions: [Permission.EDIT_SUB_CATEGORIES],
            })
          ) {
            actionItems.push({
              label: 'Edit',
              key: '1',
              icon: <EditOutlined />,
              onClick: () => {
                redirectReload(`sub_categories/${rowValue.id}`);
              },
            });
          }
          if (
            canAccess({
              permissions: [Permission.DELETE_SUB_CATEGORIES],
            })
          ) {
            actionItems.push({
              label: 'Delete',
              key: '2',
              icon: <DeleteOutlined />,
              onClick: () => {
                setShowDeleteAlert({ show: true, id: rowValue.id });
              },
            });
          }
          return (
            <span>
              <Dropdown
                menu={{
                  items: actionItems,
                }}
              >
                <AiOutlineMore size={30} />
              </Dropdown>
            </span>
          );
        },
      },
    ],
  }));
};

const handleEdit = async (axiosInstance: any, values: any, id: string) => {
  const results: any = await updatePartialSubCategory(axiosInstance)(
    values,
    id
  );
  if (results.error) {
    const toastMsg = i18n.t('global.entityUpdateFailed', {
      entity: i18n.t('global.entities.subCategory'),
    });
    toast.error(toastMsg, {
      position: i18n.language == 'ar' ? 'top-left' : 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  } else {
    const toastMsg = i18n.t('global.entityUpdatedSuccessfully', {
      entity: i18n.t('global.entities.subCategory'),
    });
    toast.success(toastMsg, {
      position: i18n.language == 'ar' ? 'top-left' : 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }
};
