import React, { useEffect, useState } from 'react';
import { IHeaderColumn, IRow } from '../../components/dataTable';
import { handleSortTableColumn } from '../../helpers/logic';
import Paragraph from '../../components/paragraph';
import Input from '../../components/input';
import { Dropdown, Switch } from 'antd';
import { AiOutlineMore } from 'react-icons/ai';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { updatePartialOrganization } from '../../api/organizations';
import Permission from '../../enum/permission.enum';
import { useGetLocalProp } from '../../hooks/getLocalProp';

export const headerColumnsBuilder = (
  searchParamsOrderby: { columnName: string; sort: 'ASC' | 'DESC' }[],
  searchParams: any,
  setSearchParams: (val: any) => void
): IHeaderColumn[] => {
  let columns: IHeaderColumn[] = [
    {
      name: 'id',
      displayedName: 'Id',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'id'));
      },
    },
    {
      name: 'name',
      displayedName: 'name',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'name'));
      },
    },
    {
      name: 'email',
      displayedName: 'Email',
    },
    {
      name: 'phone_code',
      displayedName: 'Phone code',
    },
    {
      name: 'phone_number',
      displayedName: 'Phone number',
    },
    {
      name: 'approved',
      displayedName: 'Approved',
    },
    {
      name: 'serviceType_id',
      displayedName: 'Type',
    },
    {
      name: 'created_at',
      displayedName: 'Created at',
      handleClick(column) {
        setSearchParams(handleSortTableColumn(searchParams, 'created_at'));
      },
    },
    {
      name: 'updated_at',
      displayedName: 'Updated at',
    },
    {
      name: 'actions',
      displayedName: 'Actions',
    },
  ];

  searchParamsOrderby.forEach((val, i) => {
    columns = columns.map((column) => {
      return {
        ...column,
        sorted: column.name === val.columnName ? val.sort : column.sorted,
      };
    });
  });
  return columns;
};

export const rowsBuilder = (
  organizations: any[],
  loadedOrganizations: any[],
  setLoadedOrganizations: (val: any) => void,
  redirectReload: (route: string) => void,
  setShowDeleteAlert: (val: { show: boolean; id: string }) => void,
  canAccess: any,
  getLocalProp: any,
  axiosInstance: any
): IRow[] => {
  return organizations.map((val, i) => ({
    dataObj: val,
    props: [
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.id}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return (
            <Paragraph
              editable={
                <Input
                  type='text'
                  textValue={rowValue.name}
                  onChange={(e) => {
                    setLoadedOrganizations(
                      loadedOrganizations.map((organization, i) => {
                        return organization.id == rowValue.id
                          ? { ...organization, name: e.target.value }
                          : organization;
                      })
                    );
                  }}
                />
              }
              onLeaveEdit={() => {
                handleEdit(
                  axiosInstance,
                  {
                    name: rowValue.name,
                    approved: rowValue.approved ? true : false,
                    email: rowValue.email,
                  },
                  rowValue.id
                );
              }}
            >
              {rowValue.name}
            </Paragraph>
          );
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.email}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.phone_code}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.phone_number}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return (
            <span>
              <Switch
                checked={rowValue.approved}
                onChange={(checked) => {
                  setLoadedOrganizations(
                    loadedOrganizations.map((organization, i) => {
                      return organization.id == rowValue.id
                        ? { ...organization, approved: checked }
                        : organization;
                    })
                  );
                  handleEdit(
                    axiosInstance,
                    {
                      name: rowValue.name,
                      approved: checked,
                      email: rowValue.email,
                    },
                    rowValue.id
                  );
                }}
              />
            </span>
          );
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{getLocalProp(rowValue, 'serviceType_name', '')}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.created_at}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          return <span>{rowValue.updated_at}</span>;
        },
      },
      {
        loadContent: (rowValue) => {
          const actionItems: any[] = [];
          if (
            canAccess({
              permissions: [Permission.EDIT_ORGANIZATION],
            })
          ) {
            actionItems.push({
              label: 'Edit',
              key: '1',
              icon: <EditOutlined />,
              onClick: () => {
                redirectReload(`organizations/${rowValue.id}`);
              },
            });
          }
          if (
            canAccess({
              permissions: [Permission.DELETE_ORGANIZATION],
            })
          ) {
            actionItems.push({
              label: 'Delete',
              key: '2',
              icon: <DeleteOutlined />,
              onClick: () => {
                setShowDeleteAlert({ show: true, id: rowValue.id });
              },
            });
          }
          return (
            <span>
              <Dropdown
                menu={{
                  items: actionItems,
                }}
              >
                <AiOutlineMore size={30} />
              </Dropdown>
            </span>
          );
        },
      },
    ],
  }));
};

const handleEdit = async (axiosInstance: any, values: any, id: string) => {
  const results: any = await updatePartialOrganization(axiosInstance)(
    values,
    id
  );
  if (results.error) {
    const toastMsg = i18n.t('global.entityUpdateFailed', {
      entity: i18n.t('global.entities.organization'),
    });
    toast.error(toastMsg, {
      position: i18n.language == 'ar' ? 'top-left' : 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  } else {
    const toastMsg = i18n.t('global.entityUpdatedSuccessfully', {
      entity: i18n.t('global.entities.organization'),
    });
    toast.success(toastMsg, {
      position: i18n.language == 'ar' ? 'top-left' : 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  }
};
