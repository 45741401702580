import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from 'formik';
import { randomStr, yupErrorMapping } from '../../helpers';
import TitleHeader from '../../components/titleHeader';
import Input from '../../components/input';
import Button from '../../components/button';
import { useAxios } from '../../hooks/useAxios';
import { bagSchema } from './BagFormSchema';
import i18n from '../../i18n';
import Alert from '../../components/alert';
import Constants from '../../constants';
import { Avatar } from 'antd';
import FileUpload from '../../components/file-upload';
import Select from '../../components/select';
import PortalModal from '../../components/portalModal';
import { renderError } from '../../helpers/renderers';
import RichBox from '../../components/richBox';
import { useGetLocalProp } from '../../hooks/getLocalProp';
import { fetchOwners } from '../../api/organizations';
import { fetchCategories, fetchSubCategories } from '../../api/categories';

interface IBagFormProps {
  bag?: {
    id: number;
    title_en: string;
    title_ar: string;
    brief_en: string;
    brief_ar: string;
    badge_en: string;
    badge_ar: string;
    price: string;
    created_at: string;
    updated_at: string;
    keywords: any[];
    contents: any[];
    categories: any[];
    sub_categories: any[];
    profile_image_url: string;
    content_image_url: string;
    file_url: string;
  };
  submitForm: (formValues: any) => void;
  errors: string[];
}

const BagForm = ({ bag, submitForm, errors }: IBagFormProps) => {
  const currentLang = i18n.language;
  const { axios } = useAxios();
  const { getLocalProp } = useGetLocalProp();
  const [selectImage, setSelectImage] = useState<string | undefined>();
  const profileImgRef = useRef<any>(null);
  const handleClick = () => {
    profileImgRef.current?.click();
  };
  const [isKeywordsModalOpen, setIsKeywordsModalOpen] = useState(false);
  const [isContentsModalOpen, setIsContentsModalOpen] = useState(false);
  const [keywordsHasErrors, setKeywordsHasErrors] = useState(false);
  const [contentsHasErrors, setContentsHasErrors] = useState(false);
  const [owners, setOwners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const formik = useFormik({
    initialValues: {
      title_en: '',
      title_ar: '',
      brief_en: '',
      brief_ar: '',
      badge_en: '',
      badge_ar: '',
      price: '',
      keywords: [],
      contents: [],
      categories: [],
      subCategories: [],
      photo: '',
      contentPhoto: '',
      bagFile: '',
    },
    validate: async (values) => {
      return await yupErrorMapping(bagSchema(bag ? true : false), values);
    },
    onSubmit: async (values) => {
      const mappedValues = {
        ...values,
        categories: values.categories
          .filter((obj: any) => obj.value != 'all')
          .map((obj: any) => obj.value),
        subCategories: values.subCategories
          .filter((obj: any) => obj.value != 'all')
          .map((obj: any) => obj.value),
        keywords: values.keywords.map((obj: any) => ({ keyword: obj.text })),
        contents: values.contents.map((obj: any) => ({ content: obj.text })),
      };
      submitForm(mappedValues);
    },
  });

  useEffect(() => {
    let haskeywordError = false;
    let hascontentError = false;
    Object.keys(formik.errors).forEach((errKey, i) => {
      if (errKey.includes('keywords')) {
        haskeywordError = true;
      }
      if (errKey.includes('contents')) {
        hascontentError = true;
      }
    });
    setKeywordsHasErrors(haskeywordError);
    setContentsHasErrors(hascontentError);
  }, [formik.errors]);

  useEffect(() => {
    if (bag) {
      // image load
      if (bag.profile_image_url.trim() != '') {
        setSelectImage(bag.profile_image_url);
        formik.setFieldValue('photo', bag.profile_image_url);
      }
      if (bag.content_image_url.trim() != '') {
        formik.setFieldValue('contentPhoto', bag.content_image_url);
      }
      // other feilds
      formik.setFieldValue(
        'categories',
        categories.filter((c: any) =>
          bag.categories.find((bagC) => c.value == bagC.id)
        )
      );
      formik.setFieldValue(
        'subCategories',
        subCategories.filter((c: any) =>
          bag.sub_categories.find((bagC) => c.value == bagC.id)
        )
      );
      formik.setFieldValue('title_en', bag.title_en);
      formik.setFieldValue('title_ar', bag.title_ar);
      formik.setFieldValue('badge_en', bag.badge_en);
      formik.setFieldValue('badge_ar', bag.badge_ar);
      formik.setFieldValue('brief_en', bag.brief_en);
      formik.setFieldValue('brief_ar', bag.brief_ar);
      formik.setFieldValue('price', bag.price);
      formik.setFieldValue(
        'keywords',
        bag.keywords.map((keyword: any) => ({
          key: randomStr(),
          text: keyword.keyword,
        }))
      );
      formik.setFieldValue(
        'contents',
        bag.contents.map((content: any) => ({
          key: randomStr(),
          text: content.content,
        }))
      );
      // setTouched
      setTimeout(() => {
        formik.setFieldTouched('categories', true);
        formik.setFieldTouched('subCategories', true);
        formik.setFieldTouched('title_en', true);
        formik.setFieldTouched('title_ar', true);
        formik.setFieldTouched('badge_en', true);
        formik.setFieldTouched('badge_ar', true);
        formik.setFieldTouched('brief_en', true);
        formik.setFieldTouched('brief_ar', true);
        formik.setFieldTouched('price', true);
        formik.setFieldTouched('keywords', true);
        formik.setFieldTouched('contents', true);
      }, 300);
    }
  }, [bag]);

  useEffect(() => {
    async function loadCategories() {
      const results: any = await fetchCategories(axios)();
      if (!('error' in results)) {
        setCategories(
          results.map((val: any) => ({
            label: getLocalProp(val, 'name', ''),
            value: val.id,
          }))
        );
      } else {
        setCategories([]);
      }
    }
    async function loadSubCategories() {
      const results: any = await fetchSubCategories(axios)();
      if (!('error' in results)) {
        setSubCategories(
          results.map((val: any) => ({
            label: getLocalProp(val, 'name', ''),
            value: val.id,
          }))
        );
      } else {
        setSubCategories([]);
      }
    }
    loadCategories();
    loadSubCategories();
  }, []);

  return (
    <Container fluid className='p-0 pt-0'>
      <Row className={'me-0 ms-0'}>
        <Col className='p-0'>
          <Row className={'me-0 ms-0'}>
            {errors.length > 0 && (
              <Col xs={12} className={'mt-3'}>
                <Alert
                  type='error'
                  multiple
                  content={
                    <div className='d-flex flex-column'>
                      {errors.map((error, i) => (
                        <p key={i} className='mb-0'>
                          {error}
                        </p>
                      ))}
                    </div>
                  }
                />
              </Col>
            )}
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-4 d-flex justify-content-center'>
              <Avatar
                src={selectImage ?? 'https://via.placeholder.com/600'}
                size={70}
                className='pointer'
                alt='user-avatar'
                onClick={() => handleClick()}
              />
              <FileUpload
                ref={profileImgRef}
                accept='.jpg,.jpeg,.png,.webp,.gif'
                load={(file, base64) => {
                  formik.setFieldValue('photo', file);
                  setSelectImage(base64);
                }}
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col
              md={6}
              className={`mt-4 text-align-start ${
                currentLang === 'ar' ? 'order-2' : ''
              }`}
            >
              <Input
                className='text-dir-ltr'
                type='text'
                labelText={`${i18n.t('pages.bag.title_en')}`}
                placeholder={`${i18n.t('pages.bag.title_en')}`}
                textValue={formik.values.title_en}
                onBlur={(e: any) => {
                  formik.setFieldTouched('title_en', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('title_en', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.title_en
                    ? formik.errors.title_en
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.title_en && formik.errors.title_en
                    ? formik.errors.title_en
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                className='text-dir-rtl'
                type='text'
                labelText={`${i18n.t('pages.bag.title_ar')}`}
                placeholder={`${i18n.t('pages.bag.title_ar')}`}
                textValue={formik.values.title_ar}
                onBlur={(e: any) => {
                  formik.setFieldTouched('title_ar', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('title_ar', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.title_ar
                    ? formik.errors.title_ar
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.title_ar && formik.errors.title_ar
                    ? formik.errors.title_ar
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col
              md={6}
              className={`mt-4 text-align-start ${
                currentLang === 'ar' ? 'order-2' : ''
              }`}
            >
              <Input
                className='text-dir-ltr'
                type='text'
                labelText={`${i18n.t('pages.bag.badge_en')}`}
                placeholder={`${i18n.t('pages.bag.badge_en')}`}
                textValue={formik.values.badge_en}
                onBlur={(e: any) => {
                  formik.setFieldTouched('badge_en', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('badge_en', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.badge_en
                    ? formik.errors.badge_en
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.badge_en && formik.errors.badge_en
                    ? formik.errors.badge_en
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                className='text-dir-rtl'
                type='text'
                labelText={`${i18n.t('pages.bag.badge_ar')}`}
                placeholder={`${i18n.t('pages.bag.badge_ar')}`}
                textValue={formik.values.badge_ar}
                onBlur={(e: any) => {
                  formik.setFieldTouched('badge_ar', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('badge_ar', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.badge_ar
                    ? formik.errors.badge_ar
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.badge_ar && formik.errors.badge_ar
                    ? formik.errors.badge_ar
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col
              md={6}
              className={`mt-4 text-align-start ${
                currentLang === 'ar' ? 'order-2' : ''
              }`}
            >
              <RichBox
                labelText={`${i18n.t('pages.bag.brief_en')}`}
                initialContent={formik.values.brief_en}
                onChange={(content: any) => {
                  formik.setFieldTouched('brief_en', true);
                  formik.setFieldValue('brief_en', content);
                }}
                errorMessage={
                  formik.touched.brief_en && formik.errors.brief_en
                    ? formik.errors.brief_en
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <RichBox
                labelText={`${i18n.t('pages.bag.brief_ar')}`}
                initialContent={formik.values.brief_ar}
                onChange={(content: any) => {
                  formik.setFieldTouched('brief_ar', true);
                  formik.setFieldValue('brief_ar', content);
                }}
                errorMessage={
                  formik.touched.brief_ar && formik.errors.brief_ar
                    ? formik.errors.brief_ar
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='file'
                accept='.zip,.rar'
                labelText={`${i18n.t('pages.bag.bagFile')}`}
                placeholder={`${i18n.t('pages.bag.bagFile')}`}
                textValue={formik.values.bagFile.toString()}
                onBlur={(e: any) => {
                  formik.setFieldTouched('bagFile', true);
                }}
                onFileChange={(file, base64) => {
                  formik.setFieldValue('bagFile', file);
                }}
                validationCheck={
                  formik.touched.bagFile
                    ? formik.errors.bagFile
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.bagFile && formik.errors.bagFile
                    ? formik.errors.bagFile
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t('pages.bag.price')}`}
                placeholder={`${i18n.t('pages.bag.price')}`}
                textValue={formik.values.price.toString()}
                onBlur={(e: any) => {
                  formik.setFieldTouched('price', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('price', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.price
                    ? formik.errors.price
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.price && formik.errors.price
                    ? formik.errors.price
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='file'
                labelText={`${i18n.t('pages.bag.contentImage')}`}
                placeholder={`${i18n.t('pages.bag.contentImage')}`}
                textValue={formik.values.contentPhoto.toString()}
                onBlur={(e: any) => {
                  formik.setFieldTouched('contentPhoto', true);
                }}
                onFileChange={(file, base64) => {
                  formik.setFieldValue('contentPhoto', file);
                }}
                validationCheck={
                  formik.touched.contentPhoto
                    ? formik.errors.contentPhoto
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.contentPhoto && formik.errors.contentPhoto
                    ? formik.errors.contentPhoto
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Select
                labelText={`${i18n.t('pages.bag.category')}`}
                placeholder={`${i18n.t('pages.bag.category')}`}
                isMulti
                hasSelectAll
                selectAllText={i18n.t('pages.bag.allSections') as string}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={categories}
                defaultSelectedValues={formik.values.categories as any}
                onBlur={(e) => {
                  formik.setFieldTouched('categories', true);
                }}
                onChange={(newValue, action) => {
                  formik.setFieldValue('categories', newValue);
                }}
                errorMessage={
                  formik.touched.categories && formik.errors.categories
                    ? formik.errors.categories
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Select
                labelText={`${i18n.t('pages.bag.sub_categories')}`}
                placeholder={`${i18n.t('pages.bag.sub_categories')}`}
                isMulti
                hasSelectAll
                selectAllText={i18n.t('pages.bag.allSections') as string}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={subCategories}
                defaultSelectedValues={formik.values.subCategories}
                onBlur={(e) => {
                  formik.setFieldTouched('subCategories', true);
                }}
                onChange={(newValue, action) => {
                  formik.setFieldValue('subCategories', newValue);
                }}
                errorMessage={
                  formik.touched.subCategories &&
                  formik.errors.subCategories &&
                  formik.errors.subCategories
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Button
                styleType='light'
                text={`${i18n.t('pages.bag.keywords')}`}
                onClick={() => setIsKeywordsModalOpen(true)}
              />
              {keywordsHasErrors &&
                renderError(i18n.t('pages.bag.keywordsError') as string)}
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Button
                styleType='light'
                text={`${i18n.t('pages.bag.contents')}`}
                onClick={() => setIsContentsModalOpen(true)}
              />
              {contentsHasErrors &&
                renderError(i18n.t('pages.bag.contentsError') as string)}
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-5 d-flex'>
              <Button
                styleType='light'
                text={i18n.t('global.forms.submit')}
                disabled={
                  Object.keys(formik.errors).length > 0 ||
                  Object.keys(formik.touched).length === 0
                }
                onClick={async () => {
                  formik.submitForm();
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <PortalModal
        isOpen={isKeywordsModalOpen}
        centered
        className={'align-items-center p-3'}
      >
        <Container fluid>
          {formik.values.keywords.map((keyword: any, i) => (
            <Row key={i} className={'me-0 ms-0'}>
              <Col md={1} className='d-flex align-items-start'>
                <Button
                  className={`${'mt-5'} px-3 py-0`}
                  styleType='dark'
                  text={'-'}
                  onClick={() => {
                    const updateKeywords = formik.values.keywords.filter(
                      (k: any) => k.key !== keyword.key
                    );
                    formik.setFieldValue('keywords', updateKeywords);
                  }}
                />
              </Col>
              <Col md={10} className='mt-2'>
                <Input
                  type='text'
                  labelText={`${i18n.t('pages.bag.enterKeyword')}`}
                  placeholder={`${i18n.t('pages.bag.enterKeyword')}`}
                  textValue={
                    (
                      formik.values.keywords.find(
                        (k: any, i) => k.key == keyword.key
                      ) as any
                    )?.text
                  }
                  onBlur={(e: any) => {
                    formik.setFieldTouched('keywords', true);
                  }}
                  onChange={(e: any) => {
                    const updateKeywords = formik.values.keywords.map(
                      (k: any) =>
                        k.key === keyword.key
                          ? { ...k, text: e.currentTarget.value }
                          : k
                    );
                    formik.setFieldValue('keywords', updateKeywords);
                  }}
                  validationCheck={
                    formik.touched.keywords
                      ? (formik.errors as any)[`keywords[${i}].text`]
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.keywords &&
                    (formik.errors as any)[`keywords[${i}].text`]
                      ? (formik.errors as any)[`keywords[${i}].text`]
                      : undefined
                  }
                />
              </Col>
            </Row>
          ))}
          <Row className={'mt-3 me-0 ms-0'}>
            <Col md={12} className='mt-2 d-flex justify-content-center'>
              <Button
                className='px-4'
                styleType='light'
                text={'+'}
                onClick={() => {
                  formik.setFieldValue('keywords', [
                    ...formik.values.keywords,
                    { key: randomStr(), text: '' },
                  ]);
                }}
              />
            </Col>
          </Row>
          <Row className={'mt-3 me-0 ms-0'}>
            <Col md={1} className='mt-2 d-flex'>
              <Button
                styleType='dark'
                text={`${i18n.t('global.close')}`}
                onClick={() => setIsKeywordsModalOpen(false)}
              />
            </Col>
            <Col md={2} className='mt-2 d-flex'>
              <Button
                className='px-4'
                styleType='light'
                text={`${i18n.t('global.done')}`}
                onClick={() => setIsKeywordsModalOpen(false)}
              />
            </Col>
          </Row>
        </Container>
      </PortalModal>
      <PortalModal
        isOpen={isContentsModalOpen}
        centered
        className={'align-items-center p-3'}
      >
        <Container fluid>
          {formik.values.contents.map((content: any, i) => (
            <Row key={i} className={'me-0 ms-0'}>
              <Col md={1} className='d-flex align-items-start'>
                <Button
                  className={`${'mt-5'} px-3 py-0`}
                  styleType='dark'
                  text={'-'}
                  onClick={() => {
                    const updateContents = formik.values.contents.filter(
                      (k: any) => k.key !== content.key
                    );
                    formik.setFieldValue('contents', updateContents);
                  }}
                />
              </Col>
              <Col md={10} className='mt-2'>
                <Input
                  type='text'
                  labelText={`${i18n.t('pages.bag.enterContent')}`}
                  placeholder={`${i18n.t('pages.bag.enterContent')}`}
                  textValue={
                    (
                      formik.values.contents.find(
                        (k: any, i) => k.key == content.key
                      ) as any
                    )?.text
                  }
                  onBlur={(e: any) => {
                    formik.setFieldTouched('contents', true);
                  }}
                  onChange={(e: any) => {
                    const updateContents = formik.values.contents.map(
                      (k: any) =>
                        k.key === content.key
                          ? { ...k, text: e.currentTarget.value }
                          : k
                    );
                    formik.setFieldValue('contents', updateContents);
                  }}
                  validationCheck={
                    formik.touched.contents
                      ? (formik.errors as any)[`contents[${i}].text`]
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.contents &&
                    (formik.errors as any)[`contents[${i}].text`]
                      ? (formik.errors as any)[`contents[${i}].text`]
                      : undefined
                  }
                />
              </Col>
            </Row>
          ))}
          <Row className={'mt-3 me-0 ms-0'}>
            <Col md={12} className='mt-2 d-flex justify-content-center'>
              <Button
                className='px-4'
                styleType='light'
                text={'+'}
                onClick={() => {
                  formik.setFieldValue('contents', [
                    ...formik.values.contents,
                    { key: randomStr(), text: '' },
                  ]);
                }}
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={1} className='mt-2 d-flex'>
              <Button
                styleType='dark'
                text={`${i18n.t('global.close')}`}
                onClick={() => setIsContentsModalOpen(false)}
              />
            </Col>
            <Col md={2} className='mt-2 d-flex'>
              <Button
                className='px-4'
                styleType='light'
                text={`${i18n.t('global.done')}`}
                onClick={() => setIsContentsModalOpen(false)}
              />
            </Col>
          </Row>
        </Container>
      </PortalModal>
    </Container>
  );
};

BagForm.displayName = 'BagForm';
export default BagForm;
