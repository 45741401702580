import Constants from '../constants';
import { AxiosInstance } from 'axios';
import { getApiErrorMessage } from '../helpers/mappers';

let loadStudentsController: any;

export const fetchStudents =
  (axiosInstance: AxiosInstance) =>
  (
    page?: string,
    search?: string,
    orderby?: { [key: string]: 'ASC' | 'DESC' }[]
  ) => {
    try {
      if (loadStudentsController) loadStudentsController.abort();
      loadStudentsController = new AbortController();
      const response = axiosInstance.get('/dashboard_students', {
        params: { page, search, orderby },
        signal: loadStudentsController.signal,
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const fetchStudent =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.get(`/dashboard_students/${id}`);
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const createStudent =
  (axiosInstance: AxiosInstance) => async (student: any) => {
    try {
      const response = await axiosInstance.post(
        '/dashboard_students',
        {
          firstName: student.firstName,
          lastName: student.lastName,
          email: student.email,
          password: student.password,
          phone_code: student.code,
          phone_number: student.phoneNumber,
          profile_image: student.photo,
        },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updateStudent =
  (axiosInstance: AxiosInstance) => async (student: any, id: string) => {
    try {
      let studentData: any = {
        firstName: student.firstName,
        lastName: student.lastName,
        email: student.email,
        password: student.password,
        phone_code: student.code,
        phone_number: student.phoneNumber,
      };
      if (student.photo && typeof student.photo !== 'string') {
        studentData = {
          ...studentData,
          profile_image: student.photo,
        };
      }
      const response = await axiosInstance.post(
        `/dashboard_students/${id}`,
        studentData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updatePartialStudent =
  (axiosInstance: AxiosInstance) => async (student: any, id: string) => {
    try {
      let studentData: any = {
        firstName: student.firstName,
        lastName: student.lastName,
        email: student.email,
      };
      if (student.photo && typeof student.photo !== 'string') {
        studentData = {
          ...studentData,
          profile_image: student.photo,
        };
      }
      const response = await axiosInstance.post(
        `/dashboard_students/${id}/partial`,
        studentData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const deleteStudent =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.delete(`/dashboard_students/${id}`);
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };
