import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { updateAuthUser } from '../../sagas/global/types';
import { useRedirect } from '../../hooks/useRedirect';

export type AuthWrapperProps = {
  children: any;
};

const AuthWrapper = React.forwardRef(
  ({ children }: AuthWrapperProps, ref: React.Ref<HTMLHeadingElement>) => {
    const authUser = useSelector(
      (state: AppState) => state.globalReducer.authUser
    );
    const dispatch = useDispatch();
    const redirectReload = useRedirect(true);
    const [isAuth, setIsAuth] = useState(false);
    useEffect(() => {
      const auth =
        typeof window !== 'undefined' ? localStorage.getItem('auth') : null;
      if (auth) dispatch(updateAuthUser({ authUser: JSON.parse(auth) }));
      else redirectReload('sign-in');
    }, []);
    useEffect(() => {
      if (authUser) setIsAuth(true);
    }, [authUser]);
    return <>{isAuth && children}</>;
  }
);

AuthWrapper.displayName = 'AuthWrapper';
export default AuthWrapper;
