import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormik } from 'formik';
import { yupErrorMapping } from '../../helpers';
import TitleHeader from '../../components/titleHeader';
import Input from '../../components/input';
import Button from '../../components/button';
import { useAxios } from '../../hooks/useAxios';
import { studentSchema } from './StudentFormSchema';
import i18n from '../../i18n';
import Alert from '../../components/alert';
import Constants from '../../constants';
import { Avatar } from 'antd';
import FileUpload from '../../components/file-upload';
import { useGetLocalProp } from '../../hooks/getLocalProp';
import _ from 'lodash';

interface IStudentFormProps {
  student?: {
    first_name: string;
    last_name: string;
    user: {
      email: string;
      phone_code: string;
      phone_number: string;
      permissions: {
        id: string;
        name_en: string;
        name_ar: string;
      }[];
    };
    profile_image_url: string;
  };
  submitForm: (formValues: any) => void;
  errors: string[];
}

const StudentForm = ({ student, submitForm, errors }: IStudentFormProps) => {
  const { axios } = useAxios();
  const { getLocalProp } = useGetLocalProp();
  const [selectCountryCode, setSelectCountryCode] = useState<
    { label: string; value: string } | undefined
  >();
  const [selectImage, setSelectImage] = useState<string | undefined>();
  const profileImgRef = useRef<any>(null);
  const handleClick = () => {
    profileImgRef.current?.click();
  };
  const formik = useFormik({
    initialValues: {
      firstName: student?.first_name ?? '',
      lastName: student?.last_name ?? '',
      email: student?.user.email ?? '',
      code: student?.user.phone_code ?? '',
      phoneNumber: student?.user.phone_number ?? '',
      password: '',
      passwordConfirmation: '',
      photo: student?.profile_image_url ?? '',
    },
    validate: async (values) => {
      return await yupErrorMapping(
        studentSchema(student ? true : false),
        values
      );
    },
    onSubmit: async (values) => {
      submitForm(values);
    },
  });

  useEffect(() => {
    if (student) {
      // image load
      if (student.profile_image_url.trim() != '') {
        setSelectImage(student.profile_image_url);
        formik.setFieldValue('photo', student.profile_image_url);
      }
      // load code
      const userCountryCode = _.find(
        Constants.countryCodes,
        (country) => country.value == student.user.phone_code
      );
      if (userCountryCode)
        setSelectCountryCode({
          label: i18n.t(`global.countries.${userCountryCode.key}`),
          value: userCountryCode.value,
        });
      formik.setFieldValue('code', student.user.phone_code);
      formik.setFieldValue('phoneNumber', student.user.phone_number);
      // other feilds
      formik.setFieldValue('firstName', student.first_name ?? '');
      formik.setFieldValue('lastName', student.last_name ?? '');
      formik.setFieldValue('email', student.user.email);
      // setTouched
      setTimeout(() => {
        formik.setFieldTouched('code', true);
        formik.setFieldTouched('phoneNumber', true);
        formik.setFieldTouched('firstName', true);
        formik.setFieldTouched('lastName', true);
        formik.setFieldTouched('email', true);
      }, 300);
    }
  }, [student]);

  return (
    <Container fluid className='p-0 pt-0'>
      <Row className={'me-0 ms-0'}>
        <Col className='p-0'>
          <Row className={'me-0 ms-0'}>
            {errors.length > 0 && (
              <Col xs={12} className={'mt-3'}>
                <Alert
                  type='error'
                  multiple
                  content={
                    <div className='d-flex flex-column'>
                      {errors.map((error, i) => (
                        <p key={i} className='mb-0'>
                          {error}
                        </p>
                      ))}
                    </div>
                  }
                />
              </Col>
            )}
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-4 d-flex justify-content-center'>
              <Avatar
                src={selectImage ?? 'https://via.placeholder.com/600'}
                size={70}
                className='pointer'
                alt='user-avatar'
                onClick={() => handleClick()}
              />
              <FileUpload
                ref={profileImgRef}
                accept='.jpg,.jpeg,.png,.webp,.gif'
                load={(file, base64) => {
                  formik.setFieldValue('photo', file);
                  setSelectImage(base64);
                }}
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t('global.first-name')}`}
                placeholder={`${i18n.t('global.first-name')}`}
                textValue={formik.values.firstName}
                onBlur={(e: any) => {
                  formik.setFieldTouched('firstName', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('firstName', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.firstName
                    ? formik.errors.firstName
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t('global.last-name')}`}
                placeholder={`${i18n.t('global.last-name')}`}
                textValue={formik.values.lastName}
                onBlur={(e: any) => {
                  formik.setFieldTouched('lastName', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('lastName', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.lastName
                    ? formik.errors.lastName
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.lastName && formik.errors.lastName
                    ? formik.errors.lastName
                    : undefined
                }
              />
            </Col>
          </Row>
          <Row className={'me-0 ms-0'}>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='text'
                labelText={`${i18n.t('global.email')}`}
                placeholder={`${i18n.t('global.email')}`}
                textValue={formik.values.email}
                onBlur={(e: any) => {
                  formik.setFieldTouched('email', true);
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('email', e.currentTarget.value);
                }}
                validationCheck={
                  formik.touched.email
                    ? formik.errors.email
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : undefined
                }
              />
            </Col>
            <Col md={6} className='mt-4 text-align-start'>
              <Input
                type='phone'
                labelText={`${i18n.t('global.phone-number')}`}
                placeholder={`${i18n.t('global.phone-number')}`}
                textValue={formik.values.phoneNumber}
                onBlur={(e: any) => {
                  formik.setFieldTouched('phoneNumber', true);
                  formik.validateField('phoneNumber');
                }}
                onChange={(e: any) => {
                  formik.setFieldValue('phoneNumber', e.currentTarget.value);
                }}
                codePlaceholder={`${i18n.t('global.code')}`}
                countryCodes={Constants.countryCodes.map((country) => ({
                  label: i18n.t(`global.countries.${country.key}`),
                  value: country.value,
                }))}
                defaultSelectedCountry={selectCountryCode}
                onCountryCodeChange={(newValue, action) => {
                  setSelectCountryCode({
                    label: newValue.label,
                    value: newValue.value,
                  });
                  formik.setFieldValue('code', newValue.value);
                }}
                validationCheck={
                  formik.touched.phoneNumber
                    ? formik.errors.phoneNumber
                      ? 'error'
                      : 'success'
                    : undefined
                }
                errorMessage={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? formik.errors.phoneNumber
                    : undefined
                }
              />
            </Col>
          </Row>
          {!student && (
            <Row className={'me-0 ms-0'}>
              <Col md={6} className='mt-4 text-align-start'>
                <Input
                  type='password'
                  labelText={`${i18n.t('global.password')}`}
                  placeholder={`${i18n.t('global.password')}`}
                  textValue={formik.values.password}
                  onBlur={(e: any) => {
                    formik.setFieldTouched('password', true);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('password', e.currentTarget.value);
                  }}
                  validationCheck={
                    formik.touched.password
                      ? formik.errors.password
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.password && formik.errors.password
                      ? formik.errors.password
                      : undefined
                  }
                />
              </Col>
              <Col md={6} className='mt-4 text-align-start'>
                <Input
                  type='password'
                  labelText={`${i18n.t('global.confirmPassword')}`}
                  placeholder={`${i18n.t('global.confirmPassword')}`}
                  textValue={formik.values.passwordConfirmation}
                  onBlur={(e: any) => {
                    formik.setFieldTouched('passwordConfirmation', true);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue(
                      'passwordConfirmation',
                      e.currentTarget.value
                    );
                  }}
                  validationCheck={
                    formik.touched.passwordConfirmation
                      ? formik.errors.passwordConfirmation
                        ? 'error'
                        : 'success'
                      : undefined
                  }
                  errorMessage={
                    formik.touched.passwordConfirmation &&
                    formik.errors.passwordConfirmation
                      ? formik.errors.passwordConfirmation
                      : undefined
                  }
                />
              </Col>
            </Row>
          )}
          <Row className={'me-0 ms-0'}>
            <Col md={12} className='mt-5 d-flex'>
              <Button
                styleType='light'
                text={i18n.t('global.forms.submit')}
                disabled={
                  Object.keys(formik.errors).length > 0 ||
                  Object.keys(formik.touched).length === 0
                }
                onClick={async () => {
                  formik.submitForm();
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

StudentForm.displayName = 'StudentForm';
export default StudentForm;
