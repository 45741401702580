import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Card } from 'antd';
import WebsiteSEOForm from '../../forms/websiteSEO';
import { toast } from 'react-toastify';
import i18n from '../../i18n';
import { fetchPageSettings, updatePageSettings } from '../../api/pagesSettings';
import websitePages from '../../enum/websitePages';
import { useRedirect } from '../../hooks/useRedirect';
import { useAxios } from '../../hooks/useAxios';

interface IWebsiteMarketerDetailsContainerProps {
  data?: any;
}

function WebsiteMarketerDetailsContainer(
  props: IWebsiteMarketerDetailsContainerProps
) {
  const dispatch = useDispatch();
  const { axios } = useAxios();
  const redirectReload = useRedirect(true);
  const [page, setPage] = useState<any>();
  const [seo, setSeo] = useState<any>();
  const [errors, setErrors] = useState<string[]>([]);
  useEffect(() => {
    async function loadPageSettings() {
      const results: any = await fetchPageSettings(axios)(
        websitePages.MarketerDetailsPage
      );
      if (!('error' in results)) {
        setPage(results);
        setSeo(JSON.parse(results.seo));
      }
    }
    loadPageSettings();
  }, []);
  const handleEdit = async (values: any, id: string) => {
    const results: any = await updatePageSettings(axios)(
      { seo: values, settings: page.settings },
      id
    );
    if (results.error) {
      let errors: Array<string> = [];
      if (typeof results.message === 'string') {
        errors.push(results.message);
      } else {
        if (Array.isArray(results.message))
          Object.keys(results.message).forEach((key: any) => {
            errors = [...errors, results.message[key]];
          });
        else {
          Object.keys(results.message).forEach((key: any) => {
            errors = [...errors, ...results.message[key]];
          });
        }
      }
      setErrors(errors);
      const toastMsg = i18n.t('global.entityUpdateFailed', {
        entity: i18n.t('global.entities.pageSettings'),
      });
      toast.error(toastMsg, {
        position: i18n.language == 'ar' ? 'top-left' : 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } else {
      const toastMsg = i18n.t('global.entityUpdatedSuccessfully', {
        entity: i18n.t('global.entities.pageSettings'),
      });
      setErrors([]);
      toast.success(toastMsg, {
        position: i18n.language == 'ar' ? 'top-left' : 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setTimeout(() => redirectReload('pages/marketers-details'), 500);
    }
  };

  return (
    <Row className={'m-0 p-0'}>
      <Col sm={12} className={'p-0 text-center'}>
        <Card>
          <WebsiteSEOForm
            seo={seo}
            submitForm={(formValues: any) => {
              handleEdit(formValues, page?.id);
            }}
            errors={errors}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default WebsiteMarketerDetailsContainer;
