import Constants from '../constants';
import { AxiosInstance } from 'axios';
import { getApiErrorMessage } from '../helpers/mappers';

let loadOrganizationsController: any;

export const fetchOrganizations =
  (axiosInstance: AxiosInstance) =>
  (
    page?: string,
    search?: string,
    orderby?: { [key: string]: 'ASC' | 'DESC' }[]
  ) => {
    try {
      if (loadOrganizationsController) loadOrganizationsController.abort();
      loadOrganizationsController = new AbortController();
      const response = axiosInstance.get('/dashboard_organizations', {
        params: { page, search, orderby },
        signal: loadOrganizationsController.signal,
      });
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const fetchOrganization =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.get(
        `/dashboard_organizations/${id}`
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const createOrganization =
  (axiosInstance: AxiosInstance) => async (organization: any) => {
    try {
      const response = await axiosInstance.post(
        '/dashboard_organizations',
        {
          password: organization.password,
          serviceType_id: organization.serviceType_id,
          name: organization.name,
          email: organization.email,
          phone_code: organization.phone_code,
          phone_number: organization.phone_number,
          license_number: organization.license_number,
          commercial_registration_number:
            organization.commercial_registration_number,
          communication_officer: organization.communication_officer,
          courseTypes: organization.courseTypes,
          subtitle_en: organization.subtitle_en,
          subtitle_ar: organization.subtitle_ar,
          badge_en: organization.badge_en,
          badge_ar: organization.badge_ar,
          brief_en: organization.brief_en,
          brief_ar: organization.brief_ar,
          wage: organization.wage,
          facebook_url: organization.facebook_url,
          twitter_url: organization.twitter_url,
          linkedin_url: organization.linkedin_url,
          contact_email: organization.contact_email,
          official_website_url: organization.official_website_url,
          keywords: organization.keywords,
          skills: organization.skills,
          profile_image: organization.photo,
        },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updateOrganization =
  (axiosInstance: AxiosInstance) => async (organization: any, id: string) => {
    try {
      let organizationData: any = {
        name: organization.name,
        email: organization.email,
        phone_code: organization.phone_code,
        phone_number: organization.phone_number,
        license_number: organization.license_number,
        commercial_registration_number:
          organization.commercial_registration_number,
        communication_officer: organization.communication_officer,
        courseTypes: organization.courseTypes,
        subtitle_en: organization.subtitle_en,
        subtitle_ar: organization.subtitle_ar,
        badge_en: organization.badge_en,
        badge_ar: organization.badge_ar,
        brief_en: organization.brief_en,
        brief_ar: organization.brief_ar,
        wage: organization.wage,
        facebook_url: organization.facebook_url,
        twitter_url: organization.twitter_url,
        linkedin_url: organization.linkedin_url,
        contact_email: organization.contact_email,
        official_website_url: organization.official_website_url,
        keywords: organization.keywords,
        skills: organization.skills,
      };
      if (organization.photo && typeof organization.photo !== 'string') {
        organizationData = {
          ...organizationData,
          profile_image: organization.photo,
        };
      }
      const response = await axiosInstance.post(
        `/dashboard_organizations/${id}`,
        organizationData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const updatePartialOrganization =
  (axiosInstance: AxiosInstance) => async (organization: any, id: string) => {
    try {
      let organizationData: any = {
        name: organization.name,
        approved: organization.approved,
        email: organization.email,
      };
      if (organization.photo && typeof organization.photo !== 'string') {
        organizationData = {
          ...organizationData,
          profile_image: organization.photo,
        };
      }
      const response = await axiosInstance.post(
        `/dashboard_organizations/${id}/partial`,
        organizationData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const deleteOrganization =
  (axiosInstance: AxiosInstance) => async (id: string) => {
    try {
      const response = await axiosInstance.delete(
        `/dashboard_organizations/${id}`
      );
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };

export const fetchOwners =
  (axiosInstance: AxiosInstance) => async (keyword: string) => {
    try {
      const response = await axiosInstance.get(`/organizations/${keyword}`);
      return response;
    } catch (err: any) {
      return getApiErrorMessage(err);
    }
  };
