import React, { useCallback } from 'react';
import i18n from '../i18n';

export const useGetLocalProp = () => {
  const locale = i18n.language;
  const getLocalProp = useCallback(
    (obj: any, propName: string, defaultVal?: string) => {
      const prop = obj[`${propName}_${locale}`];
      if (prop && prop !== '') return prop;
      if (defaultVal) return defaultVal;
      return undefined;
    },
    [i18n.language]
  );
  return { getLocalProp };
};
