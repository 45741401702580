import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Card } from 'antd';
import TitleHeader from '../../components/titleHeader';
import i18n from '../../i18n';
import { useParams } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios';
import { fetchOrganizationUpdateRequest } from '../../api/organizationUpdateRequests';
import { useGetLocalProp } from '../../hooks/getLocalProp';

interface IViewOrganizationUpdateContainerProps {
  data?: any;
}

function ViewOrganizationUpdateContainer(
  props: IViewOrganizationUpdateContainerProps
) {
  const dispatch = useDispatch();
  const { getLocalProp } = useGetLocalProp();
  const { id } = useParams();
  const { axios } = useAxios();
  const [updateRequest, setUpdateRequest] = useState<any>();
  useEffect(() => {
    async function loadUpdateRequest() {
      const results: any = await fetchOrganizationUpdateRequest(axios)(
        id as string
      );
      if (!('error' in results)) {
        setUpdateRequest(results);
      }
    }
    if (id) loadUpdateRequest();
  }, []);

  return (
    <Row className={'m-0 p-0'}>
      <Col sm={12} className={'p-0 text-center'}>
        <Card>
          <Row className={'m-0 p-0'}>
            <Col sm={12} className={'p-0 text-center'}>
              <TitleHeader
                type='subtitle'
                text={`${i18n.t('global.name')}: ${
                  updateRequest?.organization.name
                }`}
              />
            </Col>
            <Col sm={12} className={'p-0 text-center'}>
              <TitleHeader
                type='subtitle'
                text={`${i18n.t(
                  'pages.organization.commercial_registration_number'
                )}: ${updateRequest?.commercial_registration_number}`}
              />
            </Col>
            <Col sm={12} className={'p-0 text-center'}>
              <TitleHeader
                type='subtitle'
                text={`${i18n.t('pages.organization.license_number')}: ${
                  updateRequest?.license_number
                }`}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default ViewOrganizationUpdateContainer;
